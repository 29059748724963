.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 24px;
  touch-action: pan-y pinch-zoom;
  -webkit-user-select: none;
  user-select: none;
}

.carousel-header {
  width: 80%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
}

.carousel-arrow {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
}

.carousel-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transform: scale(1.1);
}

.carousel {
  display: block;
  flex: 1;
  justify-content: center;
  touch-action: pan-y pinch-zoom;
  -webkit-user-select: none;
  user-select: none;
}

/* Vehicle Card Styling */
.vehicle-card {
  margin: 0 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
}

.vehicle-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15) !important;
}

/* Badge Styling */
.vehicle-badge {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  animation: fadeIn 0.5s ease-out;
}

.discount-badge {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  animation: fadeIn 0.5s ease-out;
}

.unavailable-badge {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Action Buttons */
.action-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-top: auto;
}

.action-buttons button {
  min-width: 0;
  width: 100%;
  height: 32px;
  font-size: 0.75rem;
  text-transform: none;
  transition: all 0.2s ease;
}

.action-buttons button:hover {
  transform: translateY(-2px);
}

.action-buttons button .MuiButton-startIcon {
  margin-right: 4px;
}

/* Mobile optimizations */
@media (max-width: 600px) {
  .carousel-container {
    padding: 8px 0;
    margin-bottom: 16px;
  }
  
  .vehicle-card {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1) !important;
  }
  
  .carousel-arrow {
    width: 32px;
    height: 32px;
  }
  
  .vehicle-badge,
  .discount-badge {
    font-size: 0.7rem;
    padding: 3px 6px !important;
  }
  
  .unavailable-badge {
    font-size: 0.7rem;
    padding: 3px !important;
  }
  
  .action-buttons {
    gap: 4px;
  }
  
  .action-buttons button {
    height: 28px;
    font-size: 0.7rem;
    padding: 4px 2px;
  }
  
  .action-buttons button .MuiButton-startIcon {
    margin-right: 2px;
  }
  
  .action-buttons button .MuiButton-startIcon svg {
    font-size: 10px !important;
  }
}

.progressive-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.progressive-image.loading {
  opacity: 0.5;
}

.image-error {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Loading Skeleton */
.vehicle-dialog-skeleton {
  padding: 1rem;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

/* Enhanced Image Gallery */
.image-gallery-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.gallery-main {
  position: relative;
  transition: transform 0.3s ease;
  cursor: zoom-in;
}

.gallery-main.zoomed {
  transform: scale(1.5);
  cursor: zoom-out;
}

.gallery-thumbnails {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  overflow-x: auto;
  scrollbar-width: thin;
  padding-bottom: 0.5rem;
}

.thumbnail {
  flex: 0 0 80px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.thumbnail.active {
  opacity: 1;
  border: 2px solid var(--primary-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  .gallery-main.zoomed {
    transform: scale(1.2);
  }
  
  .thumbnail {
    flex: 0 0 60px;
    height: 45px;
  }
}
