/* Root variables for better height management */
:root {
  --vh: 1vh;
  --keyboard-height: 0px;
  --safe-area-bottom: env(safe-area-inset-bottom, 0px);
  --button-area-height: 80px;
}

.car-search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; /* Default center for all devices */
  z-index: 99999;
  overflow: hidden;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  overflow-y: auto !important;
  display: flex !important;
}

.car-search-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease;
  margin: 20px auto !important;
  z-index: 100000; /* Ensure higher z-index than overlay */
  padding: 0; /* Remove padding here and apply properly to content areas */
  position: relative !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  bottom: auto !important;
  transform: none !important;
}

/* Improve desktop positioning */
@media (min-width: 769px) {
  .car-search-overlay {
    align-items: center !important; /* Force center alignment */
    justify-content: center !important;
    padding-top: 0 !important; /* Remove all padding */
    padding-right: 0 !important;
    background-color: rgba(0, 0, 0, 0.6);
    overflow-y: auto;
    display: flex !important;
  }
  
  .car-search-container {
    max-width: 450px;
    width: 450px;
    max-height: 85vh;
    min-height: 400px;
    margin: 20px auto !important; /* Add margin top and bottom */
    border-radius: 16px;
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.25);
    transform: none !important;
    position: relative;
    top: auto !important;
    bottom: auto !important;
  }
  
  /* Improve header styling */
  .car-search-header {
    padding: 16px 20px 12px;
    background-color: white;
    z-index: 10;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .car-search-main-title {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 600;
  }
  
  /* Better progress dots */
  .car-search-progress {
    margin: 8px 0 4px;
    gap: 5px;
  }
  
  .progress-dot {
    width: 6px;
    height: 6px;
  }
  
  /* Fix form layout */
  .car-search-form {
    max-height: calc(80vh - 100px); /* Account for header */
    overflow-y: auto;
    padding-bottom: 0 !important;
  }
  
  /* Improve content spacing */
  .car-search-content {
    padding: 16px 20px;
    gap: 16px;
  }
  
  /* Smaller progress dots */
  .car-search-progress {
    margin: 3px 0 2px;
    gap: 3px;
  }
  
  .progress-dot {
    width: 5px;
    height: 5px;
  }
  
  /* Make welcome message more compact */
  .car-search-welcome {
    margin: 10px 20px 15px;
    padding: 15px;
  }
  
  /* Adjust form sizing */
  .car-search-form {
    max-height: 520px; /* Fixed height for better visibility */
    overflow-y: auto;
  }
  
  /* More compact content area */
  .car-search-content {
    padding: 10px 12px; /* More compact padding */
    gap: 10px; /* Reduced gap */
  }
  
  /* Adjust fuel grid for better fit */
  .fuel-type-grid {
    max-height: 300px; /* Taller grid */
    gap: 12px; /* More spacing between options */
    grid-template-columns: repeat(2, 1fr);
  }
  
  /* Further reduce action button size */
  .car-search-actions {
    padding: 12px 16px 16px;
  }
  
  .car-search-back,
  .car-search-next {
    height: 40px; /* Shorter buttons */
    font-size: 14px; /* Smaller text */
  }
  
  /* Ensure short viewports also have enough spacing */
  .short-viewport.car-search-overlay {
    padding-top: 100px; /* Less padding on shorter screens */
  }
  
  /* More padding on taller screens */
  @media (min-height: 900px) {
    .car-search-overlay {
      padding-top: 180px; /* More padding on taller screens */
    }
  }
}

.car-search-container.transitioning {
  opacity: 0.7;
  transform: scale(0.98);
}

.car-search-container.keyboard-open {
  border-radius: 12px 12px 0 0;
  max-height: 70vh;
  overflow-y: auto;
}

.car-search-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  padding: 8px;
  cursor: pointer;
  z-index: 100001; /* Ensure this is above everything in the modal */
  transition: transform 0.2s ease, background-color 0.2s ease;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.car-search-close:hover {
  transform: scale(1.1);
  background-color: #f8f8f8;
}

.car-search-close:active {
  transform: scale(0.95);
  background-color: #eee;
}

.car-search-close svg {
  width: 20px;
  height: 20px;
  color: #666;
}

.car-search-header {
  margin-bottom: 8px; /* Reduced from 12px */
  padding: 0 8px;
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: white;
  padding-top: 8px;
  padding-bottom: 8px; /* Reduced from 12px */
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.car-search-icon {
  width: 36px;
  height: 36px;
  margin-bottom: 8px;
}

.car-search-main-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
  letter-spacing: -0.01em;
  text-align: center;
}

.car-search-progress {
  display: flex;
  justify-content: center;
  gap: 6px;
  margin: 12px 0 4px;
}

.progress-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e0e0e0;
  transition: all 0.3s ease;
}

.progress-dot.active {
  background-color: var(--primary-color, #4caf50);
  transform: scale(1.2);
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.progress-dot.completed {
  background-color: var(--primary-color, #4caf50);
  opacity: 0.7;
}

.car-search-content {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 0;
  transition: opacity 0.3s ease;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 30px; /* Add more padding at the bottom */
  position: relative;
  box-sizing: border-box;
}

.car-search-title {
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: 600;
  color: #333;
}

.car-search-description {
  font-size: 14px;
  margin-bottom: 12px;
  color: #666;
  line-height: 1.4;
}

.car-search-input-wrapper {
  margin-bottom: 16px !important;
  position: relative;
  flex: 0 0 auto;
  display: flex !important;
  flex-direction: column !important;
}

.search-input-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-input {
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  box-sizing: border-box;
  font-family: inherit;
}

.search-input:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.2);
}

.search-input-suffix {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-size: 16px;
  font-weight: 500;
  pointer-events: none; /* Make sure clicks go through to the input */
}

.input-hint {
  position: absolute;
  right: 10px;
  bottom: -20px;
  font-size: 11px;
  color: #999;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2px 6px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.search-input:focus + .search-input-suffix + .input-hint,
.fuel-type-option.selected ~ .input-hint {
  opacity: 1;
}

.search-textarea {
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  resize: none;
  outline: none;
  transition: all 0.3s ease;
  box-sizing: border-box;
  max-width: 100%;
  font-family: inherit;
  line-height: 1.5;
  min-height: 120px;
}

.search-textarea:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.2);
}

/* Container for textarea to position the hint */
.textarea-container {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}

/* Add a visual indicator for Ctrl+Enter key on textarea */
.textarea-hint {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 11px;
  color: #999;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2px 5px;
  border-radius: 4px;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Show the indicator when textarea is focused */
.textarea-container:focus-within .textarea-hint {
  opacity: 1;
}

/* Improved fuel type grid for options - made scrollable if needed */
.fuel-type-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
  padding: 8px 0;
  position: relative; /* For positioning the hint */
  max-height: 320px; /* Maximum height before scrolling */
  overflow-y: auto; /* Make scrollable if too many options */
}

/* Add styling for scrollable grid */
@media (min-width: 769px) {
  .fuel-type-grid {
    max-height: 360px; /* Slightly taller on desktop */
    padding-right: 4px; /* Add space for scrollbar */
  }
  
  /* Style the scrollbar */
  .fuel-type-grid::-webkit-scrollbar {
    width: 5px;
  }
  
  .fuel-type-grid::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }
  
  .fuel-type-grid::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
  }
  
  .fuel-type-grid::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  
  /* Tighten up the fuel grid spacing */
  .fuel-type-grid {
    gap: 10px; /* Reduced from 12px */
  }
}

/* Fuel type option styling */
.fuel-type-option {
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px; /* Further reduced from 12px */
  cursor: pointer;
  transition: all 0.2s ease;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.fuel-type-option:hover {
  border-color: #bdbdbd;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.fuel-type-option.selected {
  border-color: var(--primary-color, #4caf50);
  background: rgba(76, 175, 80, 0.05);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(76, 175, 80, 0.2);
}

.fuel-type-option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.fuel-type-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px; /* Reduced from 8px */
  min-height: 40px; /* Minimum height to maintain alignment */
  justify-content: center; /* Center content vertically */
}

.fuel-type-icon {
  font-size: 16px; /* Further reduced from 18px */
  margin-bottom: 3px;
  transition: transform 0.2s ease;
}

.fuel-type-option:hover .fuel-type-icon,
.fuel-type-option.selected .fuel-type-icon {
  transform: scale(1.1);
}

.fuel-type-label {
  font-size: 12px; /* Further reduced from 13px */
  font-weight: 500;
  color: #333;
}

/* Special styling for "Non ho preferenze" option */
.fuel-type-option:last-child {
  grid-column: 1 / -1;
  text-align: center;
}

.car-search-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden; /* Prevent form overflow */
  padding-bottom: 20px !important;
  -webkit-overflow-scrolling: touch; /* Improve scrolling on iOS */
  height: 100%;
}

.car-search-actions {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: 20px;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  padding: 8px 8px 16px;
  background: white;
  z-index: 10; /* Ensure buttons are clickable */
}

.car-search-actions.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 16px;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
  z-index: 5;
  border-top: 1px solid #e0e0e0;
  margin-top: 0;
}

.car-search-back,
.car-search-next {
  flex: 1;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease;
  min-width: 100px;
  max-width: 200px;
}

.car-search-back {
  background-color: #f5f5f5;
  color: #666;
}

.car-search-back:hover {
  background-color: #eeeeee;
}

.car-search-next {
  background-color: #4caf50;
  color: white;
}

.car-search-next:hover:not(:disabled) {
  background-color: #43a047;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(76, 175, 80, 0.3);
}

.car-search-next:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Welcome banner for first-time users */
.car-search-welcome {
  background-color: #f8f9ff;
  border-radius: 10px;
  padding: 14px;
  margin-bottom: 12px;
  text-align: center;
  position: relative;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  margin-left: 10px;
  margin-right: 10px;
}

/* More compact welcome on all devices */
.welcome-icon {
  font-size: 20px;
  background-color: rgba(59, 130, 246, 0.1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 8px;
}

.car-search-welcome h2 {
  font-size: 16px;
  margin: 0 0 6px;
  font-weight: 600;
  color: #333;
}

.car-search-welcome p {
  font-size: 13px;
  margin: 0;
  color: #666;
  line-height: 1.4;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-10px); }
  60% { transform: translateY(-5px); }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .car-search-overlay {
    align-items: center !important;
    justify-content: center !important;
    padding-top: 0 !important;
  }
  
  .car-search-container {
    width: 95%;
    max-width: none;
    padding: 16px;
    border-radius: 16px;
    max-height: 85vh;
    overflow-y: auto;
    margin: 10px auto !important;
  }
  
  .car-search-content {
    padding: 10px 0;
  }
  
  .car-search-title {
    font-size: 18px;
  }

  .fuel-type-option {
    padding: 10px 8px; /* Further reduced padding */
  }
  
  .fuel-type-icon {
    font-size: 16px; /* Smaller for mobile */
  }
  
  .fuel-type-label {
    font-size: 12px; /* Smaller for mobile */
  }
}

@media (max-width: 480px) {
  /* Ensure the overlay covers the entire screen */
  .car-search-overlay {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 2000 !important;
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
  
  /* Make the container truly fullscreen */
  .car-search-container {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    height: 100% !important;
    max-height: none !important;
    margin: 0 !important;
    border-radius: 0 !important;
    z-index: 2001 !important;
    overflow-y: auto !important;
    background-color: white !important;
    box-sizing: border-box !important;
    padding: 16px 16px 80px !important;
  }
  
  /* Fix form layout */
  .car-search-form {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    overflow: visible !important;
  }
  
  /* Fix content scrolling */
  .car-search-content {
    flex: 1 !important;
    overflow-y: auto !important;
    padding-bottom: 16px !important;
  }
  
  /* Fix button placement */
  .car-search-actions {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    padding: 16px !important;
    background-color: white !important;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1) !important;
    z-index: 2002 !important;
    display: flex !important;
    justify-content: space-between !important;
    gap: 8px !important;
    margin: 0 !important;
  }
  
  /* Style buttons to be equal width */
  .car-search-back,
  .car-search-next {
    flex: 1 !important;
    max-width: none !important;
  }
  
  /* Force keyboard-open state to maintain proper styling */
  .car-search-container.keyboard-open {
    height: 100% !important;
    max-height: 100% !important;
    overflow-y: auto !important;
  }
  
  /* Remove conflicting styles that might reposition buttons */
  .car-search-container.keyboard-open .car-search-actions,
  .car-search-container:not(.keyboard-open) .car-search-actions {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }
}

/* iOS-specific fixes */
@supports (-webkit-touch-callout: none) {
  .car-search-container {
    height: 100% !important;
    max-height: 100% !important;
  }
  
  .car-search-overlay {
    height: 100% !important;
  }
  
  /* Fix iOS keyboard issues */
  @media (max-width: 480px) {
    .car-search-content {
      padding-bottom: 100px !important; /* Extra padding for iOS */
    }
    
    .car-search-container.keyboard-open .car-search-content {
      padding-bottom: 150px !important; /* Even more padding when keyboard open */
    }
  }
}

/* Remove duplicate styles */
.car-search-container.keyboard-open .car-search-input-wrapper {
  position: relative;
  padding-bottom: 0;
}

/* Add styles for keyboard-open state */
.car-search-container.keyboard-open .search-input:focus,
.car-search-container.keyboard-open .search-textarea:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 4px rgba(76, 175, 80, 0.3);
  background-color: rgba(255, 255, 255, 0.95);
  position: relative;
  z-index: 15;
}

/* Remove conflicting style */
.car-search-input-wrapper + .car-search-actions {
  margin-top: 0;
}

@media (min-width: 769px) {
  .car-search-actions {
    margin-top: 16px;
    padding: 16px 0;
    justify-content: center;
    gap: 16px;
  }
  
  .car-search-back,
  .car-search-next {
    max-width: 200px;
    min-width: 140px;
    height: 44px;
    font-size: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .car-search-next {
    background-color: var(--primary-color, #4caf50);
    color: white;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .car-search-next:hover:not(:disabled) {
    background-color: var(--primary-color-hover, #43a047);
    transform: translateY(-1px);
  }
}

/* Create a parent wrapper for the input and buttons */
.car-search-input-wrapper + .car-search-actions {
  margin-top: 0;
}

@media (max-width: 480px) {
  .car-search-container {
    padding: 16px 16px 16px !important; /* Reduce bottom padding */
  }
  
  .car-search-back,
  .car-search-next {
    max-width: none;
    flex: 1;
  }
  
  .car-search-form {
    overflow: visible;
  }
}

/* Add styles for fixed positioning on mobile */
.car-search-container.mobile-fixed-position {
  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-radius: 20px 20px 0 0;
  margin: 0;
  max-height: 95vh;
  z-index: 1100;
}

/* Add active state for buttons */
.car-search-next:active:not(:disabled) {
  transform: scale(0.98);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.car-search-back:active {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Keyboard-repositioned actions styling */
.car-search-actions.keyboard-repositioned {
  position: absolute !important;
  bottom: auto !important;
  z-index: 2002 !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

/* Add special handling for mobile inputs */
@media (max-width: 480px) {
  /* Make sure input and buttons stay visible when keyboard is open */
  .car-search-container.keyboard-open .car-search-content {
    padding-bottom: 80px;
  }
  
  /* Ensure buttons remain visible when repositioned */
  .car-search-actions.keyboard-repositioned {
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
    padding: 16px !important;
  }
  
  /* Remove transition effects that could cause flickering */
  .car-search-actions {
    transition: none !important;
  }
  
  /* Target iOS specifically for additional adjustments */
  @supports (-webkit-touch-callout: none) {
    .car-search-content {
      padding-bottom: 120px !important;
    }
    
    /* iOS needs special help with keyboard */
    .car-search-container.keyboard-open {
      height: -webkit-fill-available !important;
      max-height: -webkit-fill-available !important;
    }
    
    /* Specific iOS keyboard handling */
    .car-search-actions.keyboard-repositioned {
      position: absolute !important;
      bottom: auto !important;
    }
  }
}

/* Add new styles for better Safari support */
@supports (-webkit-touch-callout: none) {
  /* Safari-specific styles */
  .car-search-container {
    position: fixed !important;
    height: -webkit-fill-available !important;
    max-height: -webkit-fill-available !important;
  }
  
  .car-search-form {
    height: auto;
    padding-bottom: 90px; /* Extra padding for Safari */
  }
  
  .car-search-content {
    -webkit-overflow-scrolling: touch;
  }
  
  .car-search-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: env(safe-area-inset-bottom, 16px); /* Support for iPhone X+ */
    background-color: white;
    z-index: 2010;
  }
  
  .car-search-container.keyboard-open .car-search-actions {
    position: sticky !important; 
    bottom: 0 !important;
    margin-top: 16px;
  }
}

/* Styles for all mobile devices */
@media (max-width: 480px) {
  /* Make sure the input is always visible when focused */
  .car-search-input-wrapper:focus-within {
    z-index: 2005;
    position: relative;
  }
  
  /* Improved button visibility when keyboard is open */
  .car-search-container.keyboard-open .car-search-content {
    padding-bottom: 100px; /* Ensure content doesn't get hidden behind buttons */
  }
  
  /* Ensure buttons stay visible */
  .car-search-actions {
    background-color: white;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Add sticky positioning as a fallback */
  .car-search-actions.keyboard-repositioned {
    position: sticky !important;
    bottom: 0 !important;
    top: auto !important;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  }
}

/* Improve button tap areas for mobile */
.car-search-back,
.car-search-next {
  min-height: 44px; /* Apple's recommended minimum tap target size */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add visual feedback on touch */
.car-search-next:active:not(:disabled),
.car-search-back:active {
  transform: scale(0.98);
  opacity: 0.9;
}

/* Safari-specific fullscreen fixes - revised for better visibility */
@supports (-webkit-touch-callout: none) {
  /* Create a viewport-height independent layout */
  .car-search-overlay {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100vw !important;
    height: 100vh !important; /* Regular viewport height */
    height: 100% !important; /* Fallback */
    height: -webkit-fill-available !important; /* iOS Safari viewport height fix */
    margin: 0 !important;
    padding: 0 !important;
    z-index: 9999 !important; 
    background-color: rgba(0, 0, 0, 0.7) !important;
    overflow: hidden !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  
  /* Optimize container to allow for all content to be visible */
  .car-search-container {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important; /* Fallback */
    height: -webkit-fill-available !important; /* iOS Safari viewport height fix */
    margin: 0 !important;
    padding: 0 !important; /* Reset padding to zero */
    padding-top: 16px !important; /* Add top padding only */
    border-radius: 0 !important;
    z-index: 10000 !important;
    box-sizing: border-box !important;
    display: flex !important;
    flex-direction: column !important;
    overflow: hidden !important; /* Prevent body scrolling */
  }
  
  /* Header should be compact but visible */
  .car-search-header {
    flex-shrink: 0 !important;
    padding: 0 16px 8px !important;
    margin: 0 !important;
  }
  
  /* Welcome message should not take too much space */
  .car-search-welcome {
    flex-shrink: 0 !important;
    padding: 12px !important;
    margin: 0 !important;
  }
  
  /* Form should take available space without overflow */
  .car-search-form {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    overflow: auto !important;
    -webkit-overflow-scrolling: touch !important;
    margin: 0 !important;
    padding: 0 16px !important; /* Horizontal padding only */
    padding-bottom: calc(var(--button-area-height) + var(--safe-area-bottom)) !important;
  }
  
  /* Content should scroll when needed */
  .car-search-content {
    flex: 1 !important;
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch !important;
    padding: 8px 0 !important;
    display: flex !important;
    flex-direction: column !important;
  }
  
  /* Title should be compact */
  .car-search-title {
    margin-top: 0 !important;
    margin-bottom: 4px !important;
  }
  
  /* Description should be compact */
  .car-search-description {
    margin-top: 0 !important;
    margin-bottom: 8px !important;
  }
  
  /* Ensure input wrapper takes minimal space */
  .car-search-input-wrapper {
    margin-bottom: 16px !important;
    flex-shrink: 0 !important;
  }
  
  /* Fix actions/buttons to always be visible */
  .car-search-actions {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    padding: 16px !important;
    padding-bottom: calc(16px + var(--safe-area-bottom)) !important;
    background-color: white !important;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1) !important;
    z-index: 10001 !important;
    margin: 0 !important;
    height: var(--button-area-height) !important;
    box-sizing: border-box !important;
    flex-shrink: 0 !important;
    display: flex !important;
    justify-content: space-between !important;
  }
  
  /* Small inputs for text, with fixed height */
  .search-input {
    height: 50px !important;
    padding: 8px 16px !important;
    font-size: 16px !important; /* Prevent zoom on iOS */
  }
  
  /* Textarea should not be too tall */
  .search-textarea {
    min-height: 100px !important;
    max-height: 150px !important; /* Limit height on small screens */
  }
  
  /* Special handling for keyboard state */
  .car-search-container.keyboard-open {
    /* Use viewport units to ensure content visibility with keyboard */
    height: 100% !important;
    overflow: hidden !important;
    
    /* Special Safari fix to handle keyboard properly */
    bottom: var(--keyboard-height) !important;
    transition: bottom 0.3s !important;
  }
  
  /* When keyboard open, make content more compact */
  .car-search-container.keyboard-open .car-search-content {
    padding-top: 0 !important;
  }
  
  /* Adjust fuel-type grid for better visibility */
  .fuel-type-grid {
    padding: 4px 0 !important;
    column-gap: 8px !important;
    row-gap: 8px !important;
  }
  
  /* Make options more compact */
  .fuel-type-option {
    padding: 10px !important;
  }
  
  /* Reduce icon size */
  .fuel-type-icon {
    font-size: 20px !important;
    margin-bottom: 2px !important;
  }
  
  /* Fix button layout */
  .car-search-back,
  .car-search-next {
    min-height: 44px !important;
    height: 44px !important;
    padding: 0 16px !important;
    max-width: none !important;
    flex: 1 !important;
  }
  
  /* Only when keyboard is open, keep buttons visible but scrollable with content */
  .car-search-container.keyboard-open .car-search-actions {
    position: sticky !important;
    bottom: 0 !important;
    z-index: 10001 !important;
    box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.15) !important;
  }
  
  /* Important - when buttons are sticky, form needs to include them */
  .car-search-container.keyboard-open .car-search-form {
    padding-bottom: 0 !important;
    max-height: calc(100% - 60px) !important; /* Make room for header */
  }
  
  /* When keyboard is open, handle with special button behavior */
  .car-search-container.keyboard-open .car-search-form:after {
    content: "" !important;
    display: block !important;
    height: var(--button-area-height) !important; /* Space for buttons */
    flex-shrink: 0 !important;
  }
  
  /* Fix for visual scaling issues when keyboard opens */
  @media (max-height: 568px) {
    /* For very small screens like iPhone SE */
    .car-search-header, .car-search-welcome {
      transform: scale(0.9) !important;
      transform-origin: top center !important;
      margin-bottom: 0 !important;
    }
    
    .fuel-type-option {
      padding: 8px !important;
    }
    
    .car-search-title {
      font-size: 16px !important;
    }
    
    .car-search-description {
      font-size: 13px !important;
    }
  }
}

/* Add desktop-specific styling for the actions/buttons */
.car-search-actions.desktop-actions {
  position: static !important;
  box-shadow: none !important;
  border-top: none !important;
  margin-top: 24px !important;
  padding: 8px 16px 16px !important;
  background-color: transparent !important;
  justify-content: center !important;
  width: 100% !important;
}

/* Move buttons to appear right below the current action */
.car-search-actions.mobile-actions {
  position: static !important;
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
  margin-top: 16px !important;
  box-shadow: none !important;
  border-top: none !important;
  background: transparent !important;
  z-index: 1 !important;
  width: 100% !important;
  padding: 0 !important;
}

/* Add space to prevent content from being hidden by buttons */
.car-search-form {
  padding-bottom: 20px !important;
  overflow: auto !important;
}

/* Fix for scrolling issues by removing fixed height padding */
@media (max-width: 480px) {
  .car-search-content {
    padding-bottom: 20px !important;
    flex: 1 !important;
  }
  
  /* Adjust keyboard open behavior for new button position */
  .car-search-container.keyboard-open .car-search-content {
    padding-bottom: 20px !important;
  }
}

/* Make sure iOS Safari also uses the new button placement */
@supports (-webkit-touch-callout: none) {
  .car-search-actions {
    position: static !important;
    bottom: auto !important;
    left: auto !important;
    right: auto !important;
    margin-top: 16px !important;
    box-shadow: none !important;
    background: transparent !important;
  }
  
  .car-search-form {
    padding-bottom: 20px !important; /* Reduce padding now that buttons move with content */
  }
  
  /* Fix for keyboard-repositioned buttons */
  .car-search-actions.keyboard-repositioned {
    position: static !important;
    bottom: auto !important;
  }
}

/* Adjust form padding for desktop */
@media (min-width: 769px) {
  .car-search-form {
    padding-bottom: 20px !important; /* Reduce padding on desktop */
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }
  
  /* Make sure content takes available space */
  .car-search-content {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
  }
  
  /* Center buttons on desktop */
  .car-search-actions.desktop-actions {
    display: flex !important;
    justify-content: center !important;
    gap: 16px !important;
  }
  
  /* Improve button sizing on desktop */
  .car-search-actions.desktop-actions .car-search-back,
  .car-search-actions.desktop-actions .car-search-next {
    min-width: 120px !important;
    max-width: 180px !important;
  }
}

/* Adjust action buttons to appear directly after input */
.car-search-actions.mobile-actions {
  position: static !important;
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
  margin-top: 16px !important;
  box-shadow: none !important;
  border-top: none !important;
  background: transparent !important;
  z-index: 1 !important;
  width: 100% !important;
  padding: 0 !important;
}

/* Special handling for fuel type step */
.fuel-type-grid + .car-search-actions .car-search-next {
  display: none !important; /* Hide next button on fuel type step */
}

.fuel-type-grid + .car-search-actions .car-search-back {
  max-width: none !important;
  width: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
  flex: 0 1 auto !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px 20px !important;
}

/* Remove bottom fixed padding now that buttons are in the content flow */
.car-search-form {
  padding-bottom: 20px !important;
  overflow: auto !important;
}

/* Fix for Safari */
@supports (-webkit-touch-callout: none) {
  .car-search-actions {
    position: static !important;
    bottom: auto !important;
    left: auto !important;
    right: auto !important;
    margin-top: 16px !important;
    box-shadow: none !important;
    background: transparent !important;
    padding: 0 !important;
    height: auto !important;
  }
  
  /* Move form padding and adjust form padding */
  .car-search-form {
    padding-bottom: 20px !important;
  }
  
  /* Fix for keyboard-repositioned buttons */
  .car-search-actions.keyboard-repositioned {
    position: static !important;
    bottom: auto !important;
  }
}

/* Fix container padding on mobile */
@media (max-width: 480px) {
  .car-search-container {
    padding: 16px 16px 16px !important; /* Reduce bottom padding */
  }
  
  /* Fix iOS Safari issues */
  @supports (-webkit-touch-callout: none) {
    .car-search-container {
      padding-bottom: env(safe-area-inset-bottom, 16px) !important;
    }
    
    .car-search-form {
      padding-bottom: 0 !important;
    }
    
    /* Remove fixed button area */
    .car-search-container.keyboard-open .car-search-form:after {
      display: none !important;
    }
  }
}

/* Adjust button styling for better placement */
.car-search-back,
.car-search-next {
  min-height: 44px !important;
}

/* Override any conflicting styles */
.car-search-container.keyboard-open .car-search-actions,
.car-search-container:not(.keyboard-open) .car-search-actions {
  position: static !important;
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
}

/* Completely redesign the mobile navigation system */
@media (max-width: 480px) {
  /* Create a floating action button style for Next/Back on mobile */
  .car-search-actions.mobile-actions {
    position: static !important;
    display: flex !important;
    justify-content: space-between !important;
    gap: 12px !important;
    margin-top: 16px !important;
    padding: 0 !important;
    width: 100% !important;
    z-index: 10 !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  
  /* Style the back button as a small icon for mobile */
  .car-search-actions.mobile-actions .car-search-back {
    background-color: #f5f5f5 !important;
    color: #666 !important;
    width: auto !important;
    min-width: unset !important;
    flex: 0 0 auto !important;
    height: 44px !important;
    width: 44px !important;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 20px !important;
    padding: 0 !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
  }
  
  /* Style the next button as a prominent action button */
  .car-search-actions.mobile-actions .car-search-next {
    flex: 1 !important;
    max-width: none !important;
    border-radius: 22px !important;
    font-weight: 600 !important;
    box-shadow: 0 2px 8px rgba(76, 175, 80, 0.3) !important;
  }
  
  /* Special handling for fuel type step - make back button centered */
  .fuel-type-grid + .car-search-actions .car-search-back {
    width: auto !important;
    border-radius: 22px !important;
    margin: 0 auto !important;
    padding: 0 24px !important;
    height: 44px !important;
  }
  
  /* Fix keyboard open state */
  .car-search-container.keyboard-open .car-search-actions.mobile-actions {
    margin-top: 16px !important;
    position: static !important;
  }
  
  /* Create space between input and actions */
  .car-search-input-wrapper {
    margin-bottom: 8px !important;
  }
  
  /* Remove any unneeded spacers and padding */
  .car-search-container.keyboard-open .car-search-form:after {
    display: none !important;
  }
  
  /* For iOS Safari */
  @supports (-webkit-touch-callout: none) {
    .car-search-actions.mobile-actions {
      position: static !important;
      box-shadow: none !important;
      bottom: auto !important;
      height: auto !important;
    }
    
    .car-search-form {
      padding-bottom: 16px !important;
    }
    
    /* Adjust space for bottom safe area */
    .car-search-content {
      padding-bottom: env(safe-area-inset-bottom, 16px) !important;
    }
  }
}

/* Add back icon for mobile back button */
@media (max-width: 480px) {
  .car-search-actions.mobile-actions .car-search-back::before {
    content: "←" !important;  /* Simple arrow character */
    display: inline-block !important;
    font-weight: bold !important;
  }
  
  /* Hide text on back button for step 1 and above */
  .car-search-actions.mobile-actions .car-search-back span {
    display: none !important;
  }
  
  /* But keep text visible for "Annulla" on first step */
  .car-search-container[data-step="0"] .car-search-actions.mobile-actions .car-search-back span {
    display: inline !important;
  }
  
  /* And use normal styling for Cancel button */
  .car-search-container[data-step="0"] .car-search-actions.mobile-actions .car-search-back {
    width: auto !important;
    border-radius: 22px !important;
    padding: 0 16px !important;
  }
  
  /* Hide the back arrow on first step */
  .car-search-container[data-step="0"] .car-search-actions.mobile-actions .car-search-back::before {
    display: none !important;
  }
}

.car-search-direct-chat {
  margin-top: 30px;
  margin-bottom: 20px; /* Add bottom margin to prevent cutoff */
  padding: 0 20px;
  text-align: center;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding-top: 20px;
  position: relative;
  box-sizing: border-box; /* Ensure padding is included in width calculations */
}

.car-search-direct-chat::before {
  content: "oppure";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 0 10px;
  font-size: 12px;
  color: #888;
  font-weight: 500;
  z-index: 1; /* Ensure it's above the border */
}

.direct-chat-button {
  background-color: white;
  border: 1px solid rgba(76, 175, 80, 0.3);
  border-radius: 8px;
  color: #4caf50;
  font-size: 14px;
  line-height: 1.4;
  padding: 12px 16px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  transition: all 0.2s ease;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box; /* Ensure padding is included in width calculations */
  max-width: 100%; /* Prevent overflow */
}

.direct-chat-button-title {
  font-weight: 600;
  margin-bottom: 5px;
  color: #333;
}

.direct-chat-button-subtitle {
  font-size: 13px;
  color: #666;
  font-weight: normal;
}

.direct-chat-button:hover {
  background-color: rgba(76, 175, 80, 0.05);
  border-color: rgba(76, 175, 80, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

.direct-chat-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Add responsive adjustments */
@media (max-width: 480px) {
  .car-search-direct-chat {
    margin-bottom: 60px; /* More margin on mobile to prevent cutoff */
  }
  
  /* Ensure the form scrolls properly on mobile */
  .car-search-form {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }
}

@media (min-width: 769px) {
  .car-search-close {
    top: 20px;
    right: 20px;
    width: 44px;
    height: 44px;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 100001; /* Ensure it's above everything */
  }
  
  .car-search-close svg {
    width: 22px;
    height: 22px;
    color: #444; /* Darker color for better visibility */
  }
  
  .car-search-close:hover {
    transform: scale(1.1);
    background-color: #f8f8f8;
  }
  
  .car-search-close:active {
    transform: scale(0.95);
    background-color: #eee;
  }
}

@media (min-width: 769px) {
  .car-search-content {
    padding: 16px 24px; /* Reduced padding */
    gap: 16px; /* Reduced gap */
    margin-bottom: 20px;
  }
  
  .car-search-title {
    font-size: 18px; /* Larger font */
    margin-bottom: 6px; /* Reduced margin */
    color: #222;
  }
  
  .car-search-description {
    font-size: 14px;
    margin-bottom: 20px;
    color: #444;
  }
  
  /* Ensure form has proper position and scrolling */
  .car-search-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
}

/* Fix form container on desktop to ensure proper scrolling */
@media (min-width: 769px) {
  .car-search-form {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
    max-height: calc(80vh - 120px); /* Increased space for content */
    padding: 0;
    margin: 0;
  }
  
  .car-search-content {
    padding: 16px 24px; /* Reduced padding */
    gap: 16px; /* Reduced gap */
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .car-search-input-wrapper {
    margin-bottom: 24px;
  }
  
  .car-search-actions {
    padding: 16px 24px 24px;
    margin-top: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    background: white;
  }
}

/* Custom height for specific options lists when there are many items */
.car-search-form [data-step="0"] .fuel-type-grid, /* First step - common to have many options */
.car-search-form [data-step="1"] .fuel-type-grid /* Second step - often has many options */ {
  max-height: 280px; /* Shorter height to see buttons without scrolling too much */
  overflow-y: auto;
}

/* Fix vertical alignment within fuel options */
.fuel-type-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px; /* Reduced from 8px */
  min-height: 40px; /* Minimum height to maintain alignment */
  justify-content: center; /* Center content vertically */
}

@media (min-width: 769px) {
  /* Further compact the header for desktop */
  .car-search-header {
    padding: 10px 16px 8px; /* More compact */
  }
  
  /* Ensure main title is compact but readable */
  .car-search-main-title {
    font-size: 16px;
    line-height: 1.3;
  }
  
  /* Make welcome message more compact */
  .car-search-welcome {
    margin: 0 16px 12px; /* Even more compact margins */
    padding: 14px; /* Even more compact padding */
  }
  
  /* Add padding to container to ensure content doesn't touch edges */
  .car-search-container {
    padding: 0 0 16px; /* Add bottom padding */
    max-width: 420px; /* Slightly narrower for better readability */
    width: 420px;
  }
  
  /* Better scrolling behavior for grid with many items */
  .fuel-type-grid {
    scrollbar-width: thin; /* For Firefox */
    overflow-y: auto;
    max-height: 350px; /* Limit height to ensure buttons are visible */
    padding-right: 5px; /* Space for scrollbar */
  }
}

/* Viewport-specific adjustments */
.short-viewport.car-search-overlay,
.normal-viewport.car-search-overlay {
  /* Empty to ensure our media query override takes priority */
}

@media (max-width: 768px) {
  .short-viewport.car-search-overlay,
  .normal-viewport.car-search-overlay {
    padding-top: 0;
    align-items: center;
  }
} 