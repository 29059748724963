/* Root variables */
:root {
  --search-panel-width: 0px;
  --primary-color: #0073e6;
  --secondary-color: #f0f2f5;
  --text-color: #333;
  --light-text: #65676b;
  --border-color: #dddfe2;
  --background-rgb: 255, 255, 255;
  --border-rgb: 0, 0, 0;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --font-family: 'Roboto', sans-serif;
}

.search-panel-open {
  --search-panel-width: 350px;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.chat-messages {
  flex: 1;
  padding: 20px 0; /* Remove horizontal padding to prevent cutoff */
  padding-bottom: 150px; /* Increased padding to make room for the footer and questions */
  overflow-y: auto;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  background-color: #f9f9f9;
  position: relative;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.message {
  max-width: 70%;
  padding: 12px 16px;
  border-radius: 18px;
  word-break: break-word;
  line-height: 1.5;
  font-size: 14px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.bot-message {
  align-self: flex-start;
  background-color: white;
  color: #333;
  border-bottom-left-radius: 5px;
  border-left: 2px solid rgba(76, 175, 80, 0.3);
  margin-right: auto;
  margin-left: 0;
  float: left;
  clear: both;
}

.user-message {
  align-self: flex-end;
  background-color: #4caf50;
  color: white;
  border-bottom-right-radius: 5px;
  max-width: 60%;
  width: auto;
  min-width: 60px;
  margin-left: auto;
  margin-right: 0;
  text-align: left;
  box-sizing: border-box;
  display: inline-block;
  hyphens: auto;
  word-wrap: break-word;
  float: right;
  clear: both;
}

.message-input-container {
  display: flex;
  padding: 10px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.message-input {
  flex: 1;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  font-size: 14px;
  outline: none;
  transition: all 0.3s ease;
  background-color: #f5f5f5;
}

.message-input:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.send-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.send-button:hover {
  background-color: #3d9140;
  transform: translateY(-2px);
}

/* Loading animation - improved styling */
.loading-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  min-width: 60px;
  max-width: 70%;
  background-color: #ffffff;
  border-radius: 18px;
  border-bottom-left-radius: 5px;
  margin-right: auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;
  z-index: 5; /* Higher than regular messages but lower than footer elements */
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

/* Human operator typing indicator */
.human-operator-typing {
  background-color: rgba(33, 150, 243, 0.05);
  border-left: 3px solid #2196F3;
  box-shadow: 0 2px 8px rgba(33, 150, 243, 0.15);
  color: #333; /* Add explicit text color for human operator typing indicator */
}

/* AI typing indicator */
.ai-typing {
  background-color: rgba(76, 175, 80, 0.05);
  border-left: 3px solid #4caf50;
  box-shadow: 0 2px 8px rgba(76, 175, 80, 0.15);
}

.loading-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: #888;
  animation: whatsapp-typing 1.2s infinite ease-in-out both;
}

.loading-dot:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes whatsapp-typing {
  0%, 100% {
    opacity: 0.2;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(-4px);
  }
}

.operator-typing {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #333;
}

.operator-typing span:first-child {
  margin-right: 8px;
}

.operator-typing .loading-dot {
  background-color: #2196F3; /* Blue color for operator dots */
  width: 6px;
  height: 6px;
  animation: whatsapp-typing 1.2s infinite ease-in-out both;
}

/* AI typing container - WhatsApp style */
.ai-typing-container {
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
}

/* AI typing animation - WhatsApp style */
.ai-typing-animation.whatsapp-style {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 10px;
  border-radius: 12px;
  background-color: rgba(76, 175, 80, 0.08);
  width: fit-content;
}

.ai-typing-animation.whatsapp-style .loading-dot {
  width: 5px;
  height: 5px;
  background-color: #4caf50;
  opacity: 0.9;
  animation: whatsapp-typing 1.2s infinite ease-in-out both;
  margin: 0 1px;
}

/* Typing with message container */
.ai-typing-with-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.ai-typing-message {
  font-size: 14px;
  color: #555;
  padding: 2px 4px;
  margin-bottom: 2px;
  font-weight: 500;
}

@keyframes whatsapp-typing {
  0%, 100% {
    opacity: 0.4;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(-3px);
  }
}

@keyframes loading-bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Notification for unread messages */
.chat-messages.has-unread::after {
  content: "Nuovi messaggi";
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  animation: fadeInOut 2s infinite;
  cursor: pointer;
  z-index: 10;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.8; }
  50% { opacity: 1; }
}

/* Focus styles for accessibility */
.message-bubble:focus,
.loading-message:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.5);
}

/* Quick Suggestions Container */
.quick-suggestions-container {
  width: 100%;
  padding: 8px 0; /* Reduced padding */
  background-color: transparent;
  margin-top: 5px; /* Reduced top margin */
  margin-bottom: 5px;
  border-radius: 12px;
  position: relative;
  z-index: 5;
}

/* Adjust spacing after carousel messages */
.message-bubble.has-carousel + .quick-suggestions-container,
.has-carousel + .quick-suggestions-container {
  margin-top: 2px; /* Minimal space after carousels */
}

/* Quick Suggestions Styling */
.quick-suggestions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 5px 0;
  padding: 5px 10px;
  width: 100%;
  max-width: 100%;
}

/* Suggestion chip */
.suggestion-chip {
  background-color: rgba(76, 175, 80, 0.1);
  color: #4caf50;
  border: 1px solid rgba(76, 175, 80, 0.3);
  border-radius: 18px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
  max-width: 100%;
  white-space: normal;
  word-break: break-word;
  line-height: 1.4;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.suggestion-chip:hover {
  background-color: rgba(76, 175, 80, 0.2);
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.suggestion-chip:active {
  transform: translateY(0);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Highlight animation for send button when question is clicked */
.highlight-send {
  animation: pulse 2s ease-in-out;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--primary-rgb), 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(var(--primary-rgb), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--primary-rgb), 0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .chat-messages {
    padding-bottom: 180px; /* Adjusted padding for mobile - enough for questions but not too much */
  }
  
  .quick-suggestions-container {
    margin-top: 3px; /* Even less margin on mobile */
    padding: 5px 0; /* Less padding on mobile */
    margin-bottom: 10px; /* Reduced bottom margin */
  }
  
  .message-bubble.has-carousel + .quick-suggestions-container,
  .has-carousel + .quick-suggestions-container {
    margin-top: 0; /* No extra space on mobile */
  }
  
  .quick-suggestions {
    padding: 8px 5px;
  }
  
  .suggestion-chip {
    padding: 6px 12px;
    font-size: 13px;
  }
  
  .search-panel-open {
    --search-panel-width: 0px; /* No search panel width on mobile */
  }
  
  .ai-typing-animation.whatsapp-style {
    padding: 4px 8px;
  }
  
  .ai-typing-message {
    font-size: 13px;
    padding: 1px 2px;
  }
  
  .ai-typing-with-message {
    gap: 6px;
  }
  
  .loading-message {
    padding: 12px;
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .user-message {
    max-width: 75%; /* Allow slightly wider messages on very small screens */
    font-size: 13px; /* Slightly smaller font on mobile */
    padding: 10px 14px; /* Slightly smaller padding */
  }
  
  .ai-typing-animation.whatsapp-style {
    padding: 3px 6px;
  }
  
  .ai-typing-animation.whatsapp-style .loading-dot {
    width: 4px;
    height: 4px;
  }
  
  .ai-typing-message {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .user-message {
    max-width: 55%; /* Slightly narrower on larger screens */
    padding: 12px 18px; /* Slightly larger padding on desktop */
  }
}

/* Add this to ensure very short messages don't look too wide */
.user-message:only-child {
  width: fit-content; /* Use fit-content for better sizing */
}

/* For very short messages (1-3 words), limit the width */
.user-message.short-text {
  width: fit-content;
  max-width: 40%; /* Narrower for short messages */
}

/* Add a container class to ensure proper message alignment */
.message-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; /* Space between message groups */
}

.message-container.user {
  align-items: flex-end; /* Align user messages to the right */
}

.message-container.bot {
  align-items: flex-start; /* Align bot messages to the left */
}

/* Clear floats after messages */
.chat-messages::after {
  content: "";
  display: table;
  clear: both;
}

/* Special styling for carousel messages */
.message-container.bot .message-bubble.has-carousel {
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  float: none;
  clear: both;
}

/* Special styling for suggestions after carousel */
.quick-suggestions-container.after-carousel {
  margin-top: 0;
  padding-top: 2px;
}

@media (max-width: 768px) {
  .quick-suggestions-container.after-carousel {
    margin-top: 0;
    padding-top: 0;
  }
  
  /* Ensure carousels are visible on mobile */
  .message-container.bot .message-bubble.has-carousel {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 0 8px 0 !important;
    padding: 12px !important;
    display: block !important;
  }
}

@media (max-width: 600px) {
  .chat-messages {
    padding: 10px 0; /* Reduced vertical padding, no horizontal padding */
    padding-bottom: 180px; /* Consistent padding with the above media query */
  }
  
  /* Ensure message container spans full width */
  .message-container.bot .message-bubble.has-carousel {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
  }
}

.chat-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
}

.message-bubble {
  padding: 12px 16px;
  border-radius: 18px;
  max-width: 75%;
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.typing-indicator {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  border-radius: 18px;
  background-color: #f0f2f5;
  width: fit-content;
  margin-top: 8px;
  font-family: 'Roboto', sans-serif;
}

/* Desktop improvements */
@media (min-width: 768px) {
  .chat-messages {
    padding: 30px;
    padding-bottom: 150px;
  }
  
  .message-bubble.system-message {
    max-width: 90%;
    width: 90%;
    margin: 0 auto;
    padding: 25px;
    overflow: hidden; /* Prevent overflow */
  }
}

/* Add responsive adjustments for different screen sizes */
@media (max-width: 480px) {
  .user-message {
    max-width: 75%; /* Allow slightly wider messages on very small screens */
    font-size: 13px; /* Slightly smaller font on mobile */
    padding: 10px 14px; /* Slightly smaller padding */
  }
  
  .ai-typing-animation.whatsapp-style {
    padding: 3px 6px;
  }
  
  .ai-typing-animation.whatsapp-style .loading-dot {
    width: 4px;
    height: 4px;
  }
  
  .ai-typing-message {
    font-size: 12px;
  }
}

.message-car-search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  padding: 4px;
  background-color: var(--primary-color, #1976d2);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.message-car-search-button:hover {
  transform: scale(1.1);
  background-color: var(--primary-dark, #1565c0);
}

.message-car-search-button img {
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
}

.bot-message-row {
  display: flex;
  align-items: flex-start;
}

.bot-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 8px;
  background-color: #f0f0f0;
  position: relative;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

/* Remove search badge */
.bot-avatar .search-badge {
  display: none;
}

/* Remove hover effects */
.bot-avatar:hover {
  transform: none;
  border-color: transparent;
  background-color: #f0f0f0;
}

/* Remove spotlight effects */
.bot-avatar.spotlight {
  animation: none;
}

.first-avatar-highlight::before {
  display: none;
}

/* Remove the tooltip that was getting cut off */
.bot-avatar::after {
  display: none; /* Remove tooltip */
}

.bot-avatar:hover::after {
  display: none; /* Remove tooltip hover state */
}

/* Keep the focus styling for accessibility */
.bot-avatar:focus {
  outline: 2px solid var(--primary-color, #1976d2);
  outline-offset: 2px;
}

/* Update spotlight effect for first-time users to be more subtle */
.bot-avatar.spotlight {
  animation: gentle-pulse 2s infinite;
}

@keyframes gentle-pulse {
  0% { box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.2); }
  70% { box-shadow: 0 0 0 8px rgba(76, 175, 80, 0); }
  100% { box-shadow: 0 0 0 0 rgba(76, 175, 80, 0); }
}

/* Highlight first avatar on page load for new users */
.first-avatar-highlight {
  position: relative;
}

.first-avatar-highlight::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
  background: transparent;
  border: 2px dashed var(--primary-color, #1976d2);
  animation: rotate 7s linear infinite;
  pointer-events: none;
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add highlight effect for new messages when chat expands */
@keyframes newMessageHighlight {
  0%, 100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
  50% {
    box-shadow: 0 0 15px 5px rgba(76, 175, 80, 0.5);
  }
}

.new-message-highlight {
  animation: newMessageHighlight 2s ease;
  position: relative;
  z-index: 2;
}

.new-message-highlight::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(76, 175, 80, 0.05);
  border-radius: inherit;
  z-index: -1;
} 