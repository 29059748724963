/* Base container */
.toggle-chat-container {
  position: relative;
  z-index: 1000;
}

/* Animations */
@keyframes pulse {
  0% { 
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.4);
    transform: scale(1);
  }
  70% { 
    box-shadow: 0 0 0 10px rgba(34, 197, 94, 0);
    transform: scale(1.05);
  }
  100% { 
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 0);
    transform: scale(1);
  }
}

@keyframes float {
  0%, 100% { 
    transform: translateY(0px); 
  }
  50% { 
    transform: translateY(-3px); 
  }
}

@keyframes pulseAndGlow {
  0% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.4);
    transform: scale(1);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(59, 130, 246, 0);
    transform: scale(1.05);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
    transform: scale(1);
  }
}

@keyframes initialPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.6);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 0 25px rgba(59, 130, 246, 0);
    transform: scale(1.08);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
    transform: scale(1);
  }
}

@keyframes spotlight {
  0%, 100% {
    opacity: 1;
    filter: drop-shadow(0 0 5px rgba(59, 130, 246, 0.5));
  }
  50% {
    opacity: 0.85;
    filter: drop-shadow(0 0 15px rgba(59, 130, 246, 0.8));
  }
}

@keyframes wiggle {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

@keyframes twinkle {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.2);
  }
}

/* Chat body */
.chat-body {
  width: 100%;
  position: relative;
  height: calc(100vh - 120px);
  max-height: 700px;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  background: #fff;
}

/* Floating message */
.floating-message {
  position: fixed;
  bottom: 100px;
  right: 20px;
  background: #fff;
  border-radius: 18px;
  padding: 16px;
  box-shadow: 
    0 8px 24px rgba(0, 0, 0, 0.1),
    0 4px 12px rgba(0, 0, 0, 0.06),
    inset 0 0 0 1px rgba(59, 130, 246, 0.2);
  min-width: 280px;
  max-width: 340px;
  margin-bottom: 0;
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  align-items: flex-start;
  z-index: 996;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  transform-origin: bottom right;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Add speech bubble tail pointing to chat icon */
.floating-message::after {
  content: '';
  position: absolute;
  bottom: -10px;
  right: 26px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
  border-right: 1px solid rgba(59, 130, 246, 0.2);
  border-bottom: 1px solid rgba(59, 130, 246, 0.2);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);
  z-index: -1;
}

@media (min-width: 641px) {
  .floating-message {
    width: 280px;
    max-width: none;
    right: 25px;
  }
  
  .floating-message::after {
    right: 26px;
  }
}

/* Visual connection indicator when hovering either component */
.floating-message:hover,
.chat-button:hover + .floating-message {
  box-shadow: 
    0 10px 36px rgba(59, 130, 246, 0.18),
    0 6px 18px rgba(59, 130, 246, 0.1),
    inset 0 0 0 1px rgba(59, 130, 246, 0.3);
  transform: translateY(-2px) scale(1.02);
}

@media (max-width: 640px) {
  .floating-message {
    bottom: 95px;
    right: 20px;
    min-width: 260px;
    padding: 14px;
  }
  
  .floating-message::after {
    right: 26px;
    width: 16px;
    height: 16px;
  }
}

.close-message-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  opacity: 0.8;
  transition: all 0.2s ease;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  z-index: 2;
}

.close-message-btn:hover {
  opacity: 1;
  background: #f5f5f5;
  color: #ef4444;
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.close-message-btn:active {
  transform: scale(0.95);
}

@media (max-width: 640px) {
  .close-message-btn {
    top: 6px;
    right: 6px;
    padding: 6px;
    width: 28px;
    height: 28px;
  }
}

.message-content {
  flex: 1;
  position: relative;
  padding-right: 16px;
  padding-left: 4px;
  min-height: 24px;
}

/* Change this to only apply to floating-message content */
.floating-message .message-content::after {
  content: 'Clicca per iniziare la chat';
  position: absolute;
  right: 0;
  bottom: -20px;
  font-size: 12px;
  color: #3B82F6;
  opacity: 1;
  font-weight: 600;
  white-space: nowrap;
  background: #fff;
  padding: 4px 8px;
  border-radius: 100px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  pointer-events: none;
  transition: all 0.3s ease;
}

.floating-message:hover .message-content::after {
  background: #3B82F6;
  color: white;
  transform: translateY(-2px);
}

@media (max-width: 640px) {
  .floating-message .message-content::after {
    font-size: 11px;
    padding: 3px 6px;
    bottom: -18px;
  }
}

.message-text {
  color: #333;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.01em;
  font-weight: 450;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

@media (max-width: 640px) {
  .message-text {
    font-size: 13px;
  }
}

/* Chat button container */
.chat-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

/* First-time tooltip container */
.chat-button-tooltip {
  position: absolute;
  bottom: 76px;
  right: 25px;
  opacity: 0;
  animation: fadeInOut 4s ease-in-out;
  z-index: 1001;
  pointer-events: none;
}

.chat-button-tooltip-text {
  background-color: #fff;
  color: #3B82F6;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(59, 130, 246, 0.2);
}

.chat-button-tooltip-arrow {
  position: absolute;
  bottom: -6px;
  right: 26px;
  width: 12px;
  height: 12px;
  background: #fff;
  transform: rotate(45deg);
  border-right: 1px solid rgba(59, 130, 246, 0.2);
  border-bottom: 1px solid rgba(59, 130, 246, 0.2);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
}

/* Chat button */
.chat-button {
  background: #fff;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  z-index: 1000;
  border: 2px solid #fff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

.chat-button:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 32px rgba(59, 130, 246, 0.3);
}

.chat-button::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border-radius: 50%;
  pointer-events: none;
}

.chat-button:hover::before {
  animation: pulseAndGlow 1s ease-in-out infinite;
}

.chat-button.pulse-animation {
  animation: float 3s ease-in-out infinite;
  position: relative;
  z-index: 1000;
}

/* Pulse glow effect for first-time users */
.chat-button.pulse-animation::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border-radius: 50%;
  z-index: 990;
  pointer-events: none;
  animation: initialPulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translateY(10px); }
  20% { opacity: 1; transform: translateY(0); }
  80% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-10px); }
}

.chat-button img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@media (max-width: 640px) {
  .chat-button {
    width: 65px;
    height: 65px;
  }

  .chat-button-tooltip {
    bottom: 72px;
    right: 20px;
  }

  .chat-button-tooltip-text {
    font-size: 12px;
    padding: 8px 12px;
  }

  .chat-button-tooltip-arrow {
    right: 22px;
    bottom: -5px;
    width: 10px;
    height: 10px;
  }
}

/* Search CTA */
.search-cta {
  background: #3B82F6;
  border-radius: 100px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  z-index: 998;
}

.search-cta:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.2);
  background: #2563eb;
}

.search-cta:active {
  transform: translateY(-2px);
}

/* AI Label */
.ai-label {
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.95);
  color: #2563eb;
  font-size: 11px;
  padding: 4px 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid rgba(59, 130, 246, 0.2);
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}

.search-cta:hover .ai-label {
  opacity: 1;
  transform: translateX(-50%) translateY(-2px);
}

.sparkles-icon {
  color: #f59e0b;
  animation: twinkle 2s infinite;
}

/* Minimized state styles */
.search-cta.minimized {
  border-radius: 50%;
  justify-content: center;
  width: 50px !important;
  height: 50px;
  padding: 12px;
  margin-right: -5px;
}

.search-cta.minimized .search-icon-cta {
  margin: 0;
  animation: spotlight 3s infinite;
}

.search-cta:hover .search-icon-cta {
  animation: wiggle 0.5s ease-in-out;
}

.search-cta .cta-text {
  transition: opacity 0.3s, transform 0.3s;
}

/* Always bottom position - both for expanded and minimized */
.search-cta.always-bottom {
  position: fixed;
  width: auto;
  height: auto;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1), width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 8px 24px rgba(59, 130, 246, 0.25);
  border: 2px solid #fff;
}

/* Desktop positioning */
.search-cta.always-bottom.desktop {
  bottom: 33px;
  right: 85px;
}

/* Mobile positioning */
.search-cta.always-bottom.mobile {
  bottom: 33px;
  right: 80px;
}

/* Add speech bubble tail for expanded state */
.search-cta.always-bottom:not(.minimized)::after {
  content: '';
  position: absolute;
  right: -12px;
  bottom: 50%;
  width: 20px;
  height: 20px;
  background: #3B82F6;
  transform: translateY(50%) rotate(45deg);
  z-index: -1;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.05);
  transition: background 0.3s;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.search-cta.always-bottom:not(.minimized):hover::after {
  background: #2563eb;
}

/* Add a glow effect to connect the elements visually */
.search-cta.always-bottom.desktop {
  filter: drop-shadow(0 0 2px rgba(59, 130, 246, 0.2));
}

.search-cta.always-bottom.desktop:hover,
.chat-button:hover + .search-cta.always-bottom.desktop,
.search-cta.always-bottom.desktop:hover + .chat-button {
  filter: drop-shadow(0 0 5px rgba(59, 130, 246, 0.4));
}

@media (max-width: 480px) {
  .search-cta.minimized.mobile {
    padding: 10px;
    width: 40px !important;
    height: 40px;
    margin-right: -5px;
  }
  
  .search-cta.always-bottom.mobile {
    bottom: 33px;
    right: 80px;
    font-size: 14px;
  }

  .search-cta.always-bottom.mobile:not(.minimized) {
    max-width: 220px;
  }
  
  .ai-label {
    font-size: 10px;
    padding: 3px 6px;
    top: -22px;
  }
}

/* Car search container */
.car-search-container {
  position: fixed;
  bottom: clamp(220px, 40vh, 380px);
  right: clamp(20px, 4vw, 40px);
  z-index: 999;
  width: clamp(380px, 40vw, 500px);
  max-width: 90vw;
}

@media (max-width: 640px) {
  .car-search-container {
    bottom: 260px;
    right: 20px;
    width: 340px;
  }
}

/* Add subtle connection effect between chat button and message */
.chat-button-container:hover .chat-button::after {
  content: '';
  position: absolute;
  top: -15px;
  right: 35%;
  width: 8px;
  height: 8px;
  background: rgba(59, 130, 246, 0.2);
  border-radius: 50%;
  animation: float 1.5s ease-in-out infinite;
}

.chat-button-container:hover .chat-button::before {
  animation: initialPulse 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

/* Enhance the floating message with a subtle glow when chat button is hovered */
.chat-button:hover ~ .floating-message {
  box-shadow: 
    0 10px 36px rgba(59, 130, 246, 0.18),
    0 6px 18px rgba(59, 130, 246, 0.1),
    inset 0 0 0 1px rgba(59, 130, 246, 0.3);
  transform: translateY(-2px) scale(1.02);
}

/* Add keyframe for message appearance to match chat button */
@keyframes messageAppear {
  0% {
    opacity: 0;
    transform: translateY(10px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
} 