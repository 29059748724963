.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  z-index: 10;
  position: relative;
}

/* Add a special class for when human operator is active */
.chat-header.human-operator-active {
  background-color: rgba(25, 118, 210, 0.08);
  border-bottom: 2px solid #1976D2;
}

/* Add a special class for when the chat is in compact mode on mobile */
@media (max-width: 768px) {
  .chat-header.compact-mode {
    border-top: 4px solid #1976D2;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  }
}

/* Add animation for transition between AI and human operator */
.chat-header.transitioning {
  animation: headerTransition 1s ease-in-out;
}

@keyframes headerTransition {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.chat-header-title {
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  width: 100%;
}

.profile-image {
  position: relative;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #eaeaea;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  bottom: 2px;
  right: 2px;
  border: 1px solid white;
  transition: all 0.3s ease-in-out;
}

.status-indicator.online {
  background-color: #2E7D32; /* Matching AI badge color */
  animation: pulse 2s infinite;
  width: 10px;
  height: 10px;
}

.status-indicator.human-operator {
  background-color: #1976D2; /* Matching operator badge color */
  animation: humanPulse 2s infinite;
  width: 10px;
  height: 10px;
}

.status-indicator.offline {
  background-color: #ff9800;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(46, 125, 50, 0.4);
  }
  70% {
    box-shadow: 0 0 0 8px rgba(46, 125, 50, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(46, 125, 50, 0);
  }
}

@keyframes humanPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.4);
  }
  70% {
    box-shadow: 0 0 0 8px rgba(25, 118, 210, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
  }
}

.title-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.name-and-badge {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.chat-bot-name {
  font-weight: 600;
  font-size: 1.2rem;
  padding-top: 8px;
  z-index: 2;
  position: relative;
}

/* Common badge styles */
.operator-badge, .ai-badge {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9em;
  background: rgba(245, 245, 245, 0.9);
  padding: 4px 12px;
  border-radius: 999px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  /* pointer-events: auto; */
  white-space: nowrap;
}

.operator-badge {
  background-color: #1976D2;
  color: white;
}

.ai-badge {
  background-color: #f5f5f7;
  color: #333;
}

.operator-badge svg,
.ai-badge img {
  width: 16px;
  height: 16px;
}

.chat-header-actions {
  display: flex;
  gap: 10px;
}

.header-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  width: 48px;
  height: 48px;
}

.header-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #333;
  transform: translateY(-1px);
}

.close-button:hover {
  color: #f44336;
}

.ai-assist-button {
  color: #4caf50;
  position: relative;
}

.ai-assist-button:hover {
  background-color: rgba(76, 175, 80, 0.1);
}

/* Add a subtle pulse animation to the AI assist button */
.ai-assist-button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(76, 175, 80, 0.2);
  z-index: -1;
  animation: aiAssistPulse 2s infinite;
  opacity: 0;
}

@keyframes aiAssistPulse {
  0% {
    transform: scale(0.95);
    opacity: 0.7;
  }
  70% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(0.95);
    opacity: 0;
  }
}

/* Add styles for the compact mode button */
.compact-mode-button {
  color: #2196f3;
  position: relative;
}

.compact-mode-button:hover {
  background-color: rgba(33, 150, 243, 0.1);
  color: #1976d2;
}

/* Add a subtle animation for the compact mode button */
.compact-mode-button svg {
  transition: transform 0.3s ease;
}

.compact-mode-button:hover svg {
  transform: translateY(2px);
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .chat-header {
    padding: 16px 20px;
  }
  
  .profile-image {
    width: 48px;
    height: 48px;
  }
  
  .chat-bot-name {
    font-size: 18px;
  }
  
  .chat-bot-subtitle {
    font-size: 13px;
  }
  
  .operator-badge, .ai-badge {
    padding: 6px 18px;
    font-size: 14px;
  }
  
  .ai-badge img {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 768px) {
  .chat-header {
    padding: 12px 15px;
  }
  
  .profile-image {
    width: 32px;
    height: 32px;
  }
  
  .chat-bot-name {
    font-size: 14px;
  }
  
  .chat-bot-subtitle {
    font-size: 10px;
  }
  
  .header-button {
    width: 40px;
    height: 40px;
    padding: 6px;
  }
  
  .operator-badge, .ai-badge {
    padding: 4px 12px;
    font-size: 12px;
  }
  
  .ai-badge img {
    width: 20px;
    height: 20px;
  }
} 