.message-bubble {
  max-width: 70%;
  padding: 12px 16px;
  border-radius: 18px;
  margin-bottom: 4px;
  position: relative;
  animation: fadeIn 0.3s ease-out;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  line-height: 1.5;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

.message-bubble.user-message {
  align-self: flex-end;
  background-color: #f0f0f0;
  color: #333;
  border-bottom-right-radius: 5px;
  margin-left: auto;
  margin-right: 10px;
}

.message-bubble.bot-message,
.message-bubble.system-message {
  align-self: flex-start;
  background-color: #0084ff;
  color: white;
  border-bottom-left-radius: 5px;
  margin-right: auto;
  margin-left: 0;
  width: calc(100% - 40px); /* Consistent width accounting for avatar */
  box-sizing: border-box;
}

.message-container.bot .message-bubble {
  margin-left: 0;
  margin-right: auto;
  width: calc(100% - 40px); /* Consistent width accounting for avatar */
  box-sizing: border-box;
}

/* Ensure proper avatar alignment */
.message-container {
  margin-bottom: 8px;
}

.message-container.bot {
  align-items: flex-start;
  padding-left: 0;
  margin-bottom: 12px;
  width: 100%;
  box-sizing: border-box;
}

/* Add a subtle hover effect */
.message-bubble:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Add a subtle animation for new messages */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Style for message content */
.message-content {
  word-break: break-word;
  overflow-wrap: break-word;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
}

/* Style for message timestamp */
.message-timestamp {
  position: absolute;
  bottom: 4px;
  right: 8px;
  font-size: 0.7rem;
  color: #667781;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

/* Style for bot message with human operator */
.message-bubble.bot-message.human-operator {
  background-color: white;
  border-left: 2px solid #2196F3;
  color: #333;
}

/* Ensure text is visible in human operator messages */
.message-bubble.bot-message.human-operator .message-content {
  color: #333;
}

/* Ensure links are visible in human operator messages */
.message-bubble.bot-message.human-operator .message-content a,
.message-bubble.bot-message.human-operator .enhanced-link {
  color: #2196F3;
}

/* Ensure strong text is visible in human operator messages */
.message-bubble.bot-message.human-operator .message-content strong {
  color: #333;
}

/* Add a subtle animation for the typing indicator */
@keyframes typingPulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

/* Add focus styles for accessibility */
.message-bubble:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.5);
  transform: translateY(-2px);
  transition: all 0.2s ease;
}

.message-bubble.user-message:focus {
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.6);
}

.message-bubble.bot-message:focus {
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.6);
}

.message-bubble.bot-message.human-operator:focus {
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.6);
}

/* Add styles for links in messages */
.message-content a {
  color: inherit;
  text-decoration: underline;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  word-break: break-all;
}

.message-content a:hover {
  text-decoration: none;
  opacity: 0.9;
}

/* Enhanced links with icon */
.enhanced-link {
  color: inherit;
  text-decoration: underline;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  word-break: break-all;
  position: relative;
  padding-right: 20px;
  transition: all 0.2s ease;
}

.enhanced-link:hover {
  text-decoration: none;
  opacity: 0.9;
}

.link-icon {
  font-size: 14px !important;
  opacity: 0.8;
  position: relative;
  top: -1px;
}

.enhanced-link:hover .link-icon {
  opacity: 1;
}

/* Add styles for strong text in messages */
.message-content strong {
  font-weight: 600;
}

/* Improve heading styles in messages */
.message-content h1, 
.message-content h2, 
.message-content h3 {
  margin-top: 0.75em;
  margin-bottom: 0.5em;
  font-weight: 600;
  line-height: 1.25;
}

.message-content h1 {
  font-size: 1.5em;
}

.message-content h2 {
  font-size: 1.25em;
}

.message-content h3 {
  font-size: 1.125em;
}

/* Improve list styling in messages */
.message-content ul, 
.message-content ol {
  margin: 8px 0;
  padding-left: 20px;
  list-style-position: outside;
}

.message-content li {
  margin-bottom: 6px;
  padding-left: 4px;
}

/* Fix nested lists */
.message-content ul ul,
.message-content ol ol,
.message-content ul ol,
.message-content ol ul {
  margin: 6px 0 6px 0;
}

/* Add styles for code in messages */
.message-content code {
  font-family: monospace;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 90%;
}

/* Add responsive styles */
@media (max-width: 768px) {
  .message-bubble {
    max-width: calc(100% - 40px);
    width: calc(100% - 40px);
    margin-left: 0;
    margin-right: auto;
    padding: 10px 12px;
    border-radius: 16px;
    box-sizing: border-box;
  }
  
  .message-bubble.bot-message {
    width: calc(100% - 40px) !important;
    max-width: calc(100% - 40px) !important;
    margin-left: 0 !important;
    margin-right: auto !important;
    border-bottom-left-radius: 5px;
    box-sizing: border-box !important;
  }
  
  .message-bubble.has-carousel {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    width: 100% !important; /* Full width for carousel messages */
    max-width: 100% !important;
    color: inherit !important;
    border-radius: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  
  /* Text content in carousel messages */
  .message-bubble.has-carousel .message-content {
    background-color: #0084ff;
    color: white;
    padding: 12px;
    border-radius: 18px;
    border-bottom-left-radius: 5px;
    margin-bottom: 8px;
    width: calc(100% - 60px); /* Account for avatar and margins */
    margin-left: 0;
    margin-right: 12px; /* Add right margin for balance */
    box-sizing: border-box;
  }
  
  /* Ensure carousel containers take full width */
  .message-bubble.has-carousel .carousel-container-wrapper,
  .message-bubble.has-carousel .message-with-carousel,
  .message-bubble.has-carousel .full-width-carousel {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-left: 0 !important;
    margin-right: auto !important;
    padding: 10px 12px !important;
    box-sizing: border-box !important;
    border-bottom-left-radius: 5px;
  }
  
  /* Add proper padding to carousel message container */
  .message-with-carousel {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
  }
}

/* Carousel container styling */
.carousel-container-wrapper {
  margin-bottom: 5px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow: visible;
  display: block;
}

/* Make sure bot messages with carousels can be wider */
.bot-message.has-carousel {
  max-width: 90%;
}

@media (max-width: 600px) {
  /* Ensure messages with carousels have proper padding */
  .message-container.bot {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  
  /* Ensure carousel containers have proper spacing */
  .carousel-container-wrapper {
    width: calc(100% - 8px) !important;
    margin-left: 4px !important;
    margin-right: 4px !important;
    padding: 0 !important;
    overflow: visible !important;
    box-sizing: border-box !important;
  }
  
  /* Fix full width carousel to prevent clipping */
  .full-width-carousel {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box !important;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch !important;
  }
  
  /* Add proper spacing for cards */
  .full-width-carousel .custom-card {
    min-width: 240px !important;
    max-width: 270px !important;
    margin: 0 8px 0 0 !important; /* Only right margin */
    border-radius: 12px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  }
  
  /* First card needs left margin */
  .full-width-carousel .custom-card:first-child {
    margin-left: 4px !important;
  }
  
  /* Ensure all bot messages are consistent */
  .message-bubble.bot-message,
  .message-bubble.system-message,
  .bot-message.has-carousel {
    width: calc(100% - 40px) !important;
    max-width: calc(100% - 40px) !important;
    padding: 10px 12px !important;
    box-sizing: border-box !important;
    margin-left: 0 !important;
    margin-right: auto !important;
    border-bottom-left-radius: 5px;
  }
  
  /* Adjust carousel card styling */
  .full-width-carousel .custom-card {
    min-width: 240px !important;
    max-width: 270px !important;
    margin: 0 8px 0 0 !important; /* Only right margin */
    border-radius: 12px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  }
  
  /* Add spacing between carousel and avatar */
  .message-container.bot .bot-message-row {
    align-items: flex-start !important;
  }
  
  /* Clean styling for carousel containers */
  .message-with-carousel {
    margin-top: 0 !important;
    padding: 0 !important;
  }
  
  /* Fix avatar positioning on mobile */
  .bot-avatar {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    margin-left: 12px; /* Add left margin to prevent avatar from touching screen edge */
    flex-shrink: 0;
  }
  
  /* Fix bot message row layout */
  .bot-message-row {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  /* Add padding to regular bot messages to align with carousel text */
  .message-bubble.bot-message:not(.has-carousel) {
    margin-left: 12px !important; /* Match avatar left margin */
    margin-right: 12px !important; /* Add right margin for balance */
  }
}

/* Full width carousel styling */
.message-with-carousel {
  width: 100%;
  margin-top: 10px;
  margin-left: 0;
  margin-right: 0;
  box-sizing: border-box;
  overflow: hidden;
  transform: translateZ(0); /* Create a stacking context to isolate touch events */
}

.full-width-carousel {
  width: 100%;
  overflow-x: auto;
  margin-left: 0;
  margin-right: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

/* Make sure the carousel container takes full width */
.full-width-carousel .carousel-container {
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
}

@media (max-width: 600px) {
  .full-width-carousel .custom-card {
    min-width: 220px;
    max-width: 280px;
  }
}

/* Add a subtle shadow to the last message to indicate it's the most recent */
.message-bubble:last-child {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Add a special style for error messages */
.message-bubble.error-message {
  background-color: rgba(244, 67, 54, 0.1);
  color: #f44336;
  border-left: 2px solid #f44336;
}

/* Add a special style for success messages */
.message-bubble.success-message {
  background-color: rgba(76, 175, 80, 0.1);
  color: #4caf50;
  border-left: 2px solid #4caf50;
}

/* Add a special style for info messages */
.message-bubble.info-message {
  background-color: rgba(33, 150, 243, 0.1);
  color: #2196F3;
  border-left: 2px solid #2196F3;
}

/* Welcome Message Styles */
.welcome-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 0;
}

.welcome-logo,
.welcome-logo img,
.welcome-header p,
.welcome-description,
.welcome-description p {
  display: none;
}

.welcome-header h2 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 400;
  color: inherit;
  line-height: 1.4;
}

.welcome-header p {
  margin: 0;
  color: #666;
}

.welcome-description {
  text-align: center;
  margin-bottom: 16px;
}

.welcome-description p {
  margin: 0;
  color: #666;
}

/* Category buttons */
.message-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
  max-width: 100%;
  opacity: 1;
  transition: opacity 0.3s ease;
}

/* Hide categories when subcategory is selected */
.message-categories.hidden {
  opacity: 0;
  pointer-events: none;
}

.category-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Make buttons wider and take up half the space minus gap */
  width: calc(50% - 10px);
  padding: 15px 10px;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  background-color: white;
  transition: all 0.2s ease;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.category-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-color: #4CAF50;
}

.category-icon {
  font-size: 24px;
  margin-bottom: 8px;
  color: #4CAF50;
}

.category-title {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #333;
  /* Ensure text wraps properly */
  width: 100%;
  word-wrap: break-word;
  hyphens: auto;
  line-height: 1.2;
}

.category-button.active {
  background-color: #f0f9f0;
  border-color: #4CAF50;
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(76, 175, 80, 0.2);
}

/* Subcategories container with transition */
.subcategories-container {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 15px;
  margin-top: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  max-width: 100%;
  overflow: hidden;
  opacity: 0;
  transform: translateY(10px);
  animation: slideIn 0.3s ease forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.subcategories-title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.subcategories-title .material-icons {
  font-size: 20px;
  margin-right: 10px;
  color: #4CAF50;
}

.subcategories-title h3 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

/* Adaptive grid for subcategories */
.subcategories-grid {
  display: grid;
  /* Always show 2 columns on mobile */
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  /* Ensure grid stays within container */
  max-width: 100%;
}

/* Adjust grid based on number of subcategories */
.subcategories-grid.items-3,
.subcategories-grid.items-4 {
  grid-template-columns: repeat(2, 1fr);
}

.subcategories-grid.items-5,
.subcategories-grid.items-6 {
  grid-template-columns: repeat(2, 1fr);
}

.subcategory-button {
  padding: 12px 10px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  transition: all 0.2s ease;
  cursor: pointer;
  /* Ensure text wraps properly */
  word-wrap: break-word;
  hyphens: auto;
  line-height: 1.2;
  /* Prevent overflow */
  overflow: hidden;
  text-overflow: ellipsis;
  /* Minimum height for consistency */
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subcategory-button:hover {
  background-color: #f5f5f5;
  border-color: #4CAF50;
}

.subcategory-button.active {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(76, 175, 80, 0.2);
}

/* Visual flow indicators */
.subcategories-container::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f9f9f9;
}

.appointment-form::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f9f9f9;
}

/* Ensure proper positioning for arrows */
.subcategories-container,
.appointment-form {
  position: relative;
}

/* Improve focus visibility */
.category-button:focus,
.subcategory-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #4CAF50, 0 4px 12px rgba(76, 175, 80, 0.2);
}

/* Add transition for all interactive elements */
.category-button,
.subcategory-button,
.form-control,
.submit-button {
  transition: all 0.2s ease;
}

/* Desktop improvements for welcome message and categories */
@media (min-width: 768px) {
  .welcome-header h2 {
    font-size: 1.2rem;
  }
  
  .welcome-logo img {
    width: 150px;
  }
  
  .welcome-header p {
    font-size: 18px;
  }
  
  .welcome-description p {
    font-size: 16px;
  }
  
  /* Improve categories layout for desktop */
  .message-categories {
    gap: 15px;
    margin-top: 30px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    /* Force two rows with 2 buttons per row */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
  }
  
  .category-button {
    width: 100%;
    padding: 20px 15px;
    border-radius: 16px;
    /* Ensure height is sufficient for content */
    min-height: 100px;
    max-width: none;
  }
  
  .category-icon {
    font-size: 32px;
    margin-bottom: 12px;
  }
  
  .category-title {
    font-size: 16px;
    /* Allow text to wrap naturally */
    min-height: auto;
    line-height: 1.3;
  }
  
  /* Improve subcategories for desktop */
  .subcategories-container {
    padding: 20px;
    margin-top: 20px;
    border-radius: 16px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .subcategories-title {
    margin-bottom: 20px;
  }
  
  .subcategories-title .material-icons {
    font-size: 24px;
  }
  
  .subcategories-title h3 {
    font-size: 18px;
  }
  
  /* Adaptive grid for subcategories on desktop */
  .subcategories-grid {
    /* Always show 2 rows on desktop */
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  /* Adjust grid based on number of subcategories */
  .subcategories-grid.items-3 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "item1 item2"
      "item3 item3";
  }
  
  .subcategories-grid.items-4 {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .subcategories-grid.items-5 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "item1 item2 item3"
      "item4 item5 .";
  }
  
  .subcategories-grid.items-6 {
    grid-template-columns: repeat(3, 1fr);
  }
  
  /* Position items in grid areas */
  .subcategories-grid.items-3 .subcategory-button:nth-child(1) { grid-area: item1; }
  .subcategories-grid.items-3 .subcategory-button:nth-child(2) { grid-area: item2; }
  .subcategories-grid.items-3 .subcategory-button:nth-child(3) { grid-area: item3; }
  
  .subcategories-grid.items-5 .subcategory-button:nth-child(1) { grid-area: item1; }
  .subcategories-grid.items-5 .subcategory-button:nth-child(2) { grid-area: item2; }
  .subcategories-grid.items-5 .subcategory-button:nth-child(3) { grid-area: item3; }
  .subcategories-grid.items-5 .subcategory-button:nth-child(4) { grid-area: item4; }
  .subcategories-grid.items-5 .subcategory-button:nth-child(5) { grid-area: item5; }
  
  .subcategory-button {
    padding: 15px;
    font-size: 15px;
    border-radius: 10px;
    /* Ensure height is sufficient for text */
    min-height: 60px;
  }
}

@media (min-width: 768px) {
  .message-bubble {
    padding: 15px 20px;
    max-width: 60%;
    font-size: 15px;
  }
  
  .message-bubble.user-message {
    border-bottom-right-radius: 6px;
    margin-right: 15px;
  }
  
  .message-bubble.bot-message {
    border-bottom-left-radius: 6px;
    margin-left: 15px;
  }
}

/* Form field validation */
.form-control.field-valid {
  border-color: #4CAF50;
  background-color: #f0fff0;
  padding-right: 35px; /* Make space for the icon */
}

.field-valid-icon {
  position: absolute;
  right: 10px;
  color: #4CAF50;
  font-size: 18px;
}

/* Position the icon for different form fields */
.form-group .field-valid-icon {
  top: 38px; /* Default position for regular inputs */
}

.calendar-date-picker .field-valid-icon,
.calendar-time-picker .field-valid-icon {
  top: 38px;
}

.form-group textarea + .field-valid-icon {
  top: 38px;
  right: 10px;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

.field-help-text {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
  font-style: italic;
}

.form-disclaimer {
  margin-top: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  border-left: 3px solid #4CAF50;
}

.form-disclaimer p {
  font-size: 13px;
  color: #555;
  margin: 0;
}

/* Desktop improvements */
@media (min-width: 768px) {
  .field-help-text {
    font-size: 13px;
  }
  
  .form-disclaimer {
    padding: 15px;
  }
  
  .form-disclaimer p {
    font-size: 14px;
  }
  
  .field-valid-icon {
    font-size: 20px;
  }
}

/* Desktop improvements for forms */
@media (min-width: 768px) {
  /* Improve form styling for desktop */
  .appointment-form {
    padding: 25px;
    border-radius: 16px;
    margin-top: 20px;
  }
  
  .form-header h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }
  
  .form-note {
    font-size: 14px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 15px;
    margin-bottom: 8px;
  }
  
  .form-control {
    padding: 12px 15px;
    font-size: 15px;
    border-radius: 10px;
  }
  
  .submit-button {
    padding: 14px 28px;
    font-size: 16px;
    border-radius: 10px;
  }
  
  /* Improve calendar and time range for desktop */
  .calendar-container {
    flex-direction: row;
    gap: 20px;
  }
  
  .calendar-date-picker,
  .calendar-time-picker {
    flex: 1;
  }
  
  .calendar-date-picker label,
  .calendar-time-picker label {
    font-size: 15px;
  }
  
  .calendar-date-picker input[type="date"],
  .calendar-time-picker select {
    padding: 12px 15px;
    font-size: 15px;
  }
  
  .time-range-selector {
    flex-direction: row;
    gap: 15px;
  }
  
  .time-range-option {
    flex: 1;
    padding: 15px;
  }
  
  .time-range-label {
    font-size: 15px;
  }
  
  .time-range-label .material-icons {
    font-size: 20px;
  }
}

/* Form styles */
.appointment-form {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 20px;
  margin-top: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  opacity: 0;
  transform: translateY(10px);
  animation: slideIn 0.3s ease forwards;
}

.form-header {
  margin-bottom: 20px;
  text-align: center;
}

.form-header h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

.form-note {
  font-size: 12px;
  color: #666;
  font-style: italic;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.form-control:focus {
  border-color: #4CAF50;
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.form-actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.submit-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
}

.submit-button:hover {
  background-color: #3d8b40;
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.submit-button:active {
  transform: translateY(0);
}

/* Success message styling */
.form-success-message {
  text-align: center;
  padding: 20px;
  background-color: #f0f9f0;
  border-radius: 12px;
  margin-top: 15px;
}

.success-icon {
  color: #4CAF50;
  font-size: 48px;
  margin-bottom: 10px;
}

.form-success-message h3 {
  color: #4CAF50;
  margin-bottom: 10px;
  font-size: 18px;
}

.form-success-message p {
  color: #666;
  font-size: 14px;
}

/* Time Range Selector */
.time-range-selector {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.time-range-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  transition: all 0.2s ease;
}

.time-range-option:hover {
  background-color: #f5f5f5;
}

.time-range-option input[type="radio"] {
  margin-right: 10px;
}

.time-range-label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.time-range-label .material-icons {
  margin-right: 8px;
  font-size: 18px;
}

.time-range-option input[type="radio"]:checked + .time-range-label {
  font-weight: 600;
  color: #4CAF50;
}

.time-range-option:has(input[type="radio"]:checked) {
  border-color: #4CAF50;
  background-color: rgba(76, 175, 80, 0.05);
}

/* Calendar Styles */
.calendar-group {
  margin-bottom: 20px;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.calendar-date-picker,
.calendar-time-picker {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.calendar-date-picker label,
.calendar-time-picker label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.calendar-date-picker input[type="date"],
.calendar-time-picker select {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  transition: border-color 0.2s ease;
}

.calendar-date-picker input[type="date"]:focus,
.calendar-time-picker select:focus {
  border-color: #4CAF50;
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.calendar-time-picker select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
  padding-right: 30px;
}

/* Radio group styles */
.radio-group {
  display: flex;
  gap: 20px;
  margin-top: 5px;
}

.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: normal;
}

.radio-label input {
  margin-right: 8px;
  cursor: pointer;
}

/* Fix carousel visibility on mobile */
.message-bubble.has-carousel {
  margin-bottom: 10px; /* Reduced from default spacing */
  width: calc(100% - 40px) !important; /* Same width as regular bubbles, accounting for avatar */
  max-width: calc(100% - 40px) !important; /* Matching width */
  float: none; /* Prevent floating issues */
  clear: both; /* Clear any floats */
  padding-left: 8px; /* Reduce left padding */
  padding-right: 8px; /* Reduce right padding */
  margin-left: 0 !important;
  margin-right: auto !important;
  box-sizing: border-box !important;
}

.carousel-container-wrapper {
  margin-bottom: 5px; /* Reduced bottom margin */
  width: 100%; /* Ensure full width */
  overflow: visible; /* Allow overflow for carousel items */
  display: block; /* Force block display */
}

.full-width-carousel {
  margin-bottom: 5px; /* Reduced bottom margin */
  width: 100%; /* Ensure full width */
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

/* Ensure carousel is visible on mobile */
@media (max-width: 768px) {
  .message-bubble.has-carousel {
    margin-bottom: 8px; /* Even less space on mobile */
    width: calc(100% - 40px) !important; /* Same width as regular bubbles */
    max-width: calc(100% - 40px) !important; /* Override max-width */
    float: none !important; /* Prevent floating issues */
    display: block !important; /* Force block display */
    padding-left: 4px !important; /* Minimal left padding on mobile */
    padding-right: 4px !important; /* Minimal right padding on mobile */
    margin-left: 0 !important;
    margin-right: auto !important;
  }
  
  .message-bubble.bot-message {
    width: calc(100% - 40px) !important; 
    max-width: calc(100% - 40px) !important;
    box-sizing: border-box !important;
    margin-left: 0 !important;
    margin-right: auto !important;
  }
  
  .carousel-container-wrapper {
    margin-bottom: 3px; /* Minimal margin on mobile */
    width: 100% !important; /* Force full width */
    display: block !important; /* Force block display */
    margin-left: 0 !important; /* Reset any left margin */
  }
  
  .full-width-carousel {
    margin-bottom: 3px; /* Minimal margin on mobile */
    width: 100% !important; /* Force full width */
    margin-left: 0 !important; /* Reset any left margin */
  }
  
  /* Fix for message container with carousel */
  .message-container.bot .message-bubble.has-carousel {
    width: 100% !important; /* Force full width */
    max-width: 100% !important; /* Override max-width */
    margin-left: 0 !important; /* Reset margin */
    margin-right: 0 !important; /* Reset margin */
  }
}

/* Fix for carousel container inside message bubble */
.message-bubble .carousel-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  /* Ensure bot message rows are consistent */
  .bot-message-row {
    width: 100%;
    display: flex;
    align-items: flex-start; /* Avatar at top when carousel is present */
  }
  
  /* Ensure carousel containers match parent bubble */
  .message-bubble.has-carousel .carousel-container-wrapper,
  .message-bubble.has-carousel .message-with-carousel,
  .message-bubble.has-carousel .full-width-carousel {
    width: 100% !important;
    max-width: 100% !important;
    margin: 8px 0 0 0 !important;
    padding: 0 !important;
    box-sizing: border-box !important;
  }

  /* Specific styling for carousel containers */
  .carousel-container-wrapper {
    margin: 8px 0 0 0 !important;
    width: 100% !important;
    padding: 0 !important;
    box-sizing: border-box !important;
  }
}

/* Fix for lists that might be directly in the message bubble */
.message-bubble ul,
.message-bubble ol {
  margin: 8px 0;
  padding-left: 20px;
  list-style-position: outside;
}

.message-bubble li {
  margin-bottom: 6px;
  padding-left: 4px;
}

/* Car listing styles */
.car-listings {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
  width: 100%;
}

.car-item {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  padding: 12px;
  border-left: 3px solid #4caf50;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.bot-message .car-item {
  background-color: rgba(255, 255, 255, 0.7);
}

.user-message .car-item {
  background-color: rgba(255, 255, 255, 0.2);
  border-left-color: rgba(255, 255, 255, 0.5);
}

.car-item strong {
  display: inline-block;
  margin-bottom: 4px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .car-item {
    padding: 10px;
  }
}

/* Back button styles */
.back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: none;
  background-color: transparent;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
  margin-bottom: 15px;
}

.back-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #4CAF50;
}

.back-button .material-icons {
  font-size: 20px;
}

/* Subcategories header layout */
.subcategories-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

/* Improve transitions */
.message-categories,
.subcategories-container,
.appointment-form {
  opacity: 0;
  transform: translateY(10px);
  animation: slideIn 0.3s ease forwards;
}

/* Modify the slideIn animation to be smoother */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add slide out animation */
@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

/* Desktop improvements */
@media (min-width: 768px) {
  .back-button {
    padding: 10px 15px;
    font-size: 15px;
  }

  .back-button .material-icons {
    font-size: 24px;
  }

  .subcategories-header {
    margin-bottom: 20px;
  }
}

/* Ensure proper spacing in form header */
.form-header {
  position: relative;
  padding-top: 10px;
}

.form-header .back-button {
  position: absolute;
  left: 0;
  top: 0;
}

/* Add transition for category buttons when going back */
.category-button {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Improve spacing between back button and content */
.subcategories-title {
  margin-top: 5px;
}

.form-header h3 {
  margin-top: 5px;
}

/* Improved list formatting */
.formatted-list {
  margin: 10px 0;
  padding-left: 20px;
}

.formatted-list li {
  margin-bottom: 5px;
}

/* Fix message bubble positioning */
.message-container {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  position: relative;
}

.message-container.user {
  justify-content: flex-end;
}

.message-container.bot {
  justify-content: flex-start;
}

/* Ensure message bubbles are on different levels */
.message-bubble {
  max-width: 70%;
  margin-bottom: 16px;
  clear: both;
}

.user-message {
  float: right;
}

.bot-message {
  float: left;
}

/* Clear floats to ensure proper spacing */
.chat-messages::after {
  content: "";
  display: table;
  clear: both;
}

/* Ensure proper spacing between messages */
.message-bubble + .message-bubble {
  margin-top: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .message-bubble {
    max-width: 85%;
  }
  
  .enhanced-link {
    word-break: break-all;
  }
}

/* Bot avatar styling */
.bot-avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  align-self: center;
}

/* Message container with avatar layout */
.message-container {
  display: flex;
  width: 100%;
  margin-bottom: 0;
  position: relative;
  flex-direction: column;
}

.message-container.bot {
  align-items: flex-start;
  padding-left: 0;
  margin-bottom: 12px;
  width: 100%;
  box-sizing: border-box;
}

.message-container.user {
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.bot-message-row {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.bot-info {
  display: flex;
  align-items: center;
  margin-left: 36px;
  margin-top: 4px;
  font-size: 13px;
  color: #888;
}

.bot-name {
  font-weight: 500;
  margin-right: 6px;
}

/* Ensure proper spacing between consecutive messages */
.message-container + .message-container {
  margin-top: 8px;
}

@media (min-width: 768px) {
  .bot-avatar {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 768px) {
  /* Improve carousel touch scrolling */
  .full-width-carousel {
    width: 100% !important;
    max-width: 100% !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    -webkit-overflow-scrolling: touch !important;
    padding: 0 !important;
    margin: 0 !important;
    position: relative !important;
    left: 0 !important;
    scroll-snap-type: x mandatory !important; /* Improve scroll behavior */
    display: flex !important;
    flex-wrap: nowrap !important;
  }
  
  /* Fix carousel container to left edge */
  .carousel-container-wrapper {
    margin: 8px 0 0 0 !important;
    width: 100% !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    overflow-x: visible !important;
    overflow-y: hidden !important;
    position: relative !important;
    left: 0 !important;
  }
  
  /* Make carousel cards snap during scrolling */
  .full-width-carousel .custom-card {
    scroll-snap-align: start !important;
    flex-shrink: 0 !important;
  }
}

/* Fix for mobile - stop carousel events from bubbling to parent chat */
@media (max-width: 768px) {
  /* Isolate carousel touch events from main chat */
  .message-with-carousel {
    touch-action: pan-x !important; /* Only allow horizontal touch actions */
    isolation: isolate !important; /* Create isolation context */
    position: relative !important;
    z-index: 1 !important; /* Ensure proper stacking */
  }
  
  /* Prevent parent container scrolling when touching carousel */
  .message-bubble.has-carousel {
    touch-action: manipulation !important;
  }
  
  /* Make sure outer container doesn't scroll horizontally */
  .message-container.bot {
    overflow-x: hidden !important;
    max-width: 100% !important;
  }
}

/* Typography */
.date-label {
  background: #E1F2FF;
  padding: 5px 12px;
  border-radius: 8px;
  font-size: 0.8rem;
  color: #54656F;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
  font-family: 'Roboto', sans-serif;
}

/* Tool indicator styles */
.tool-indicator {
  display: flex;
  align-items: center;
  margin-left: 8px;
  position: relative;
  width: 32px;
  height: 32px;
}

.tool-indicator-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
}

.tool-indicator-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  color: var(--primary-color, #1976d2);
  z-index: 1;
}

.ai-typing-container {
  display: flex;
  align-items: center;
}

/* Updated typing animation to be more subtle */
.ai-typing-animation.whatsapp-style {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 4px;
}

.loading-message {

  transform: translateY(-10px);
} 