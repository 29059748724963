.chat-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 10px 16px;
  background-color: transparent;
  border-top: none;
  z-index: 20;
  transition: all 0.3s ease;
  pointer-events: none;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

/* Add specific styles for compact/reduced mode */
.chat-footer.reduced-mode {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: calc(10px + env(safe-area-inset-bottom, 0));
  opacity: 1 !important;
  pointer-events: auto !important;
  z-index: 1500;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

/* Override any parent container styles that might affect visibility */
.ChatAndSearchContainer.page-scrolling .chat-footer.reduced-mode {
  opacity: 1 !important;
  visibility: visible !important;
  pointer-events: auto !important;
}

/* Special styling for the CTA placeholder */
.message-input-container.cta-active .message-input::placeholder {
  color: #4caf50;
  font-weight: 600;
  transition: all 0.3s ease;
  animation: pulsePlaceholder 2s infinite ease-in-out;
}

@keyframes pulsePlaceholder {
  0%, 100% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.6;
  }
}

.message-input-container.cta-active {
  border: 1px solid rgba(76, 175, 80, 0.5);
  background-color: rgba(76, 175, 80, 0.08);
  transition: all 0.3s ease;
  cursor: pointer;
  animation: glowBorder 2s infinite ease-in-out;
  transform: scale(1.01);
}

.message-input-container.cta-active:hover {
  background-color: rgba(76, 175, 80, 0.15);
  border-color: rgba(76, 175, 80, 0.8);
  transform: scale(1.02);
}

.message-input-container.cta-active:active {
  transform: scale(0.98);
  transition: transform 0.1s ease;
}

/* Make the input appear disabled when CTA is active */
.message-input-container.cta-active .message-input {
  pointer-events: none;
  background-color: transparent;
}

@keyframes glowBorder {
  0%, 100% {
    border-color: rgba(76, 175, 80, 0.5);
    box-shadow: 0 0 0 rgba(76, 175, 80, 0);
  }
  50% {
    border-color: rgba(76, 175, 80, 0.8);
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.3);
  }
}

/* Add a subtle right arrow to indicate this is clickable */
.message-input-container.cta-active::after {
  content: '→';
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #4caf50;
  font-size: 18px;
  opacity: 0.7;
  animation: moveRight 1.5s infinite ease-in-out;
}

@keyframes moveRight {
  0%, 100% {
    transform: translateY(-50%) translateX(0);
  }
  50% {
    transform: translateY(-50%) translateX(3px);
  }
}

.chat-footer:focus-within {
  background-color: transparent;
}

/* Quick suggestions container */
.quick-suggestions-container {
  width: 100%;
  padding: 10px 0;
  background-color: rgba(var(--background-rgb), 0.7);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-top: 1px solid rgba(var(--border-rgb), 0.1);
  margin-bottom: 10px;
  border-radius: 12px;
  position: relative;
  z-index: 5;
  animation: fadeIn 0.3s ease;
}

/* Quick suggestions */
.quick-suggestions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  justify-content: center;
}

/* Suggestion chip */
.suggestion-chip {
  background-color: white;
  border: 1px solid rgba(76, 175, 80, 0.3);
  color: #4caf50;
  padding: 8px 14px;
  border-radius: 16px;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
}

.suggestion-chip:hover {
  background-color: rgba(76, 175, 80, 0.1);
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

/* Footer form */
.footer-form {
  width: 100%;
  position: relative;
  z-index: 20;
  max-width: 800px;
  margin: 0 auto;
}

/* Footer input container */
.footer-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border-radius: 24px;
  padding: 4px 8px;
  box-shadow: none;
  transition: all 0.3s ease;
  border: none;
  min-height: 52px;
  position: relative;
  z-index: 20;
}

.footer-input-container:focus-within {
  box-shadow: none;
  border-color: transparent;
  transform: none;
}

/* Message input container */
.message-input-container {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  padding: 0 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 12px;
}

/* Message input */
.message-input {
  flex: 1;
  border: none;
  background: transparent;
  padding: 12px 16px;
  font-size: 16px;
  color: var(--text-color);
  outline: none;
  resize: none;
  max-height: 120px;
  min-height: 24px;
  overflow-y: auto;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.message-input:disabled {
  background-color: transparent;
  color: #999;
  cursor: not-allowed;
}

/* Suggestion button */
.suggestion-button {
  background: none;
  border: none;
  color: #666;
  font-size: 20px;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  position: relative;
}

.suggestion-button:hover {
  color: #4caf50;
  background-color: rgba(76, 175, 80, 0.1);
}

.suggestion-button.active {
  color: #4caf50;
}

.suggestion-button.has-new::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  background-color: #4caf50;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

/* Send button */
.send-button {
  background-color: rgba(76, 175, 80, 0.9);
  color: white;
  border: none;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 20px;
  position: relative;
  overflow: visible;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-right: 0;
  transform: translateX(-8px);
}

.send-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: none;
}

.send-button svg {
  stroke-width: 3px;
  display: block;
  color: white;
  fill: none;
  min-width: 24px;
  min-height: 24px;
}

.send-button:hover:not(:disabled) {
  background-color: rgba(76, 175, 80, 1);
  transform: translateY(-1px);
}

.send-button:disabled {
  background-color: rgba(204, 204, 204, 0.7);
  cursor: not-allowed;
  opacity: 0.7;
}

.send-button.highlight-send {
  animation: highlightSend 1.5s ease;
}

@keyframes highlightSend {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4);
  }
  20% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  40% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
  60% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  80% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .chat-footer {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 10px 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  /* Ensure chat footer stays visible in reduced/compact mode */
  .chat-footer.reduced-mode {
    opacity: 1 !important;
    pointer-events: auto !important;
    transform: translateY(0) !important;
    visibility: visible !important;
  }
  
  /* Override parent container transparency */
  .ChatAndSearchContainer.page-scrolling .chat-footer.reduced-mode {
    opacity: 1 !important;
  }
  
  .footer-input-container {
    padding: 4px 6px;
  }
  
  .message-input-container {
    margin-right: 10px;
  }
  
  .message-input {
    font-size: 14px;
  }
  
  .suggestion-chip {
    font-size: 13px;
    padding: 6px 12px;
  }
  
  .send-button {
    width: 46px;
    height: 46px;
    margin-right: 0;
    transform: translateX(-8px);
  }
  
  .send-icon-container {
    transform: none;
  }
  
  .send-button svg {
    stroke-width: 3px;
    min-width: 20px;
    min-height: 20px;
    transform: scale(0.75);
  }
  
  .questions-toggle-button {
    width: 44px;
    height: 44px;
    font-size: 24px;
  }
  
  /* Fix for the focus issue */
  .message-input:focus {
    z-index: 1;
  }
  
  /* Prevent auto-focus on mobile */
  .chat-footer {
    pointer-events: auto;
  }
}

/* Desktop improvements */
@media (min-width: 768px) {
  .chat-footer {
    padding: 12px 20px 16px;
  }
  
  .quick-suggestions-container {
    margin-bottom: 12px;
  }
  
  .quick-suggestions {
    gap: 10px;
  }
  
  .suggestion-chip {
    font-size: 15px;
    padding: 10px 16px;
  }
  
  .footer-input-container {
    padding: 10px 15px;
  }
  
  .message-input {
    font-size: 16px;
  }
  
  .send-button {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

/* Questions toggle button (lightbulb) */
.questions-toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(241, 196, 15, 0.1);
  border: none;
  color: #aaa;
  font-size: 22px;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  margin-right: 5px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.questions-toggle-button:hover {
  color: #f1c40f;
  background-color: rgba(241, 196, 15, 0.2);
}

.questions-toggle-button.active {
  color: #f1c40f;
  background-color: rgba(241, 196, 15, 0.2);
}

/* New questions indicator - lightbulb is lit */
.has-new-questions {
  color: #f1c40f !important;
  animation: glow 2s infinite;
  background-color: rgba(241, 196, 15, 0.3) !important;
}

@keyframes glow {
  0% {
    filter: drop-shadow(0 0 2px rgba(241, 196, 15, 0.7));
  }
  50% {
    filter: drop-shadow(0 0 8px rgba(241, 196, 15, 0.9));
  }
  100% {
    filter: drop-shadow(0 0 2px rgba(241, 196, 15, 0.7));
  }
}

/* Make sure the form and its children receive pointer events */
.footer-form,
.footer-input-container,
.questions-toggle-button,
.message-input-container,
.message-input,
.send-button {
  pointer-events: auto;
}

/* Replace the problematic styles with these more targeted ones */
/* Remove the html, body styles that are causing the site to be blocked */
/* Instead, add these styles that only affect the chat container */

#chat-root {
  touch-action: manipulation;
  -ms-touch-action: manipulation;
}

.message-input {
  /* Keep existing styles */
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Prevent iOS from zooming on input focus - keep this */
@media screen and (-webkit-min-device-pixel-ratio: 0) { 
  #chat-root select,
  #chat-root textarea,
  #chat-root input {
    font-size: 16px !important;
  }
}

/* Highlight effect for send button when question clicked */
.send-button.active {
  background-color: rgba(76, 175, 80, 1);
}

.highlight-send {
  animation: highlightSend 1.5s ease;
}

@keyframes highlightSend {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4);
  }
  20% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  40% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
  60% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  80% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
  100% {
    transform: scale(1);
  }
}

/* Spacing adjustments for questions visibility */
.chat-messages.questions-visible {
  padding-bottom: 80px;
}

/* Responsiveness for questions */
@media (max-width: 768px) {
  .quick-suggestions-container {
    padding: 8px;
  }
  
  .quick-suggestions {
    gap: 6px;
  }
  
  .suggestion-chip {
    font-size: 13px;
    padding: 6px 10px;
    max-width: 180px;
  }
  
  .questions-toggle-button {
    width: 36px;
    height: 36px;
  }
}

/* Make the input look like a button in CTA mode especially on mobile */
.reduced-mode .message-input-container.cta-active {
  border: 1px solid rgba(76, 175, 80, 0.6);
  background-color: rgba(76, 175, 80, 0.12);
  box-shadow: 0 2px 6px rgba(76, 175, 80, 0.2);
  transform: scale(1.02);
  transition: all 0.2s ease;
  border-radius: 20px;
}

.reduced-mode .message-input-container.cta-active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(76, 175, 80, 0.04);
  border-radius: inherit;
  z-index: -1;
}

/* Make the textarea in CTA mode look more like a button text */
.reduced-mode .message-input-container.cta-active .message-input {
  text-align: center;
  font-weight: 500;
  color: #4caf50;
  cursor: pointer;
  pointer-events: none;
}

/* Add a "tap" animation when the CTA is active */
@keyframes tapPulse {
  0% { transform: scale(1); }
  50% { transform: scale(0.98); }
  100% { transform: scale(1); }
}

.reduced-mode .message-input-container.cta-active:active {
  animation: tapPulse 0.3s ease;
  background-color: rgba(76, 175, 80, 0.2);
}

/* Make the entire footer container in CTA mode appear more clickable */
.chat-footer.reduced-mode .message-input-container.cta-active::after {
  font-family: 'Arial', sans-serif;
  content: '⟶ Click to open';
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #4caf50;
  font-size: 13px;
  font-weight: 600;
  opacity: 0.8;
  pointer-events: none;
  animation: fadeInOut 2s infinite ease-in-out;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
}

/* For Italian language */
html[lang="it"] .chat-footer.reduced-mode .message-input-container.cta-active::after {
  content: '⟶ Fai clic per aprire';
}

@media (max-width: 480px) {
  .chat-footer.reduced-mode .message-input-container.cta-active::after {
    font-size: 11px;
    right: 15px;
  }
}

/* ENHANCED STYLING FOR REDUCED MODE FOOTER */
/* Make the reduced mode footer more prominent and always visible */
.chat-footer.reduced-mode {
  background-color: #ffffff;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.25);
  border-top: 2px solid rgba(76, 175, 80, 0.5);
  opacity: 1 !important;
  visibility: visible !important;
  z-index: 1500;
  padding-top: 12px;
  padding-bottom: calc(12px + env(safe-area-inset-bottom, 0));
  transform: translateY(0) !important;
}

/* Ensure reduced mode footer is NEVER transparent */
.ChatAndSearchContainer.page-scrolling .chat-footer.reduced-mode {
  opacity: 1 !important;
  visibility: visible !important;
  pointer-events: auto !important;
  background-color: #ffffff !important;
}

/* Enhanced CTA styling for better visibility */
.reduced-mode .message-input-container.cta-active {
  border: 2px solid #4caf50;
  background-color: rgba(76, 175, 80, 0.15);
  box-shadow: 0 3px 10px rgba(76, 175, 80, 0.3);
  transform: scale(1.02);
  transition: all 0.2s ease;
  border-radius: 20px;
}

.reduced-mode .message-input-container.cta-active .message-input {
  color: #2e7d32;
  font-weight: 600;
}

.reduced-mode .message-input-container.cta-active .message-input::placeholder {
  color: #2e7d32;
  font-weight: 600;
}

/* Make the CTA hint more visible */
.chat-footer.reduced-mode .message-input-container.cta-active::after {
  content: '⟶ Clicca per scoprire di più';
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  color: #2e7d32;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.9;
  background-color: rgba(76, 175, 80, 0.1);
  padding: 4px 8px;
  border-radius: 12px;
  animation: pulseHint 2s infinite ease-in-out;
}

@keyframes pulseHint {
  0%, 100% { opacity: 0.9; background-color: rgba(76, 175, 80, 0.1); }
  50% { opacity: 1; background-color: rgba(76, 175, 80, 0.2); }
}

/* Remove language-specific variation since we'll use Italian by default */
html[lang="it"] .chat-footer.reduced-mode .message-input-container.cta-active::after {
  content: '⟶ Clicca per scoprire di più';
}

/* Regular input styling in reduced mode */
.reduced-mode .message-input-container {
  border: 2px solid rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Send button in reduced mode */
.reduced-mode .send-button:not(:disabled) {
  background-color: #4caf50;
  box-shadow: 0 2px 8px rgba(76, 175, 80, 0.4);
}

@media (max-width: 480px) {
  .chat-footer.reduced-mode .message-input-container.cta-active::after {
    font-size: 12px;
    right: 10px;
    padding: 4px 8px;
  }
}

/* IMPROVED STYLING FOR BETTER CONTRAST */
/* Enhanced CTA styling with better contrast */
.reduced-mode .message-input-container.cta-active {
  border: 2px solid #2e7d32;
  background-color: rgba(76, 175, 80, 0.25);
  box-shadow: 0 4px 12px rgba(46, 125, 50, 0.4);
  transform: scale(1.02);
}

.reduced-mode .message-input-container.cta-active .message-input {
  color: #1b5e20;
  font-weight: 700;
}

.reduced-mode .message-input-container.cta-active .message-input::placeholder {
  color: #1b5e20;
  font-weight: 700;
}

/* Make the CTA hint ALWAYS visible with better contrast */
.chat-footer.reduced-mode .message-input-container.cta-active::after {
  content: '⟶ Clicca per scoprire di più';
  position: absolute;
  top: -10px;
  right: 20px;
  transform: translateY(-50%);
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  opacity: 1;
  background-color: #2e7d32;
  padding: 4px 8px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation: none;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.2;
  z-index: 10;
}

.reduced-mode .message-input-container.cta-active .message-input {
  color: #1b5e20;
  font-weight: 700;
  text-align: center;
  padding-right: 40px;
}

.reduced-mode .message-input-container.cta-active .message-input::placeholder {
  color: #1b5e20;
  font-weight: 700;
  text-align: center;
}

.chat-footer.reduced-mode .message-input-container.cta-active:hover::after {
  background-color: #1b5e20;
}

/* Make sure the footer never becomes transparent */
.ChatAndSearchContainer.page-scrolling .chat-footer.reduced-mode,
.chat-footer.reduced-mode {
  opacity: 1 !important;
  visibility: visible !important;
  pointer-events: auto !important;
  background-color: #ffffff !important;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.25) !important;
  border-top: 2px solid #2e7d32 !important;
  z-index: 2000 !important; /* Higher z-index to ensure it stays on top */
}

@media (max-width: 480px) {
  .chat-footer.reduced-mode .message-input-container.cta-active::after {
    font-size: 11px;
    right: 15px;
    top: -8px;
    padding: 3px 6px;
    max-width: 130px;
  }
} 